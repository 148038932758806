import { Stack, TextField, Typography } from "@mui/material";
import EasyButton from "../../../components/easyButton";
import axios from "axios";
import { BaseURL, panelPageMargin } from "../../../utils/consts";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import Box from "@mui/material/Box";
import Footer from "../../../components/v2/footer";
import CircularProgress from "@mui/material/CircularProgress";
import Panel from "../../../components/v2/panel";
import PsychologicalQuestions from "./psychologicalQuestions";

export default function BookingQuestions() {
  const [qNo, setQNo] = useState(0);
  const [loading, setLoading] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [textInput, setTextInput] = useState("");
  const { meetingID } = useParams();
  const navigate = useNavigate();

  const questions = [
    {
      q: "What option service would you like?",
      index: 0,
      options: [
        "Opinion on an on-going case",
        "Want to know my rights",
        "Want to a legal perspective",
        "Mutual divorce",
        "consented divorce",
        "maintenance settlement or alimony negotiation",
        "child custody case",
        "Domestic violence",
        "False promise to marry",
        "My Rights in Live in relationship",
        "Others",
      ],
    },
  ];

  const submit = async (lastAnswer) => {
    try {
      setLoading(true);
      await axios.post(
        `${BaseURL}/api/client/addMeetingQuestions`,
        {
          answers: [
            ...answers,
            {
              question: questions[questions.length - 1].q,
              answer: lastAnswer,
            },
          ],
          meetingID,
        },
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  if (useParams().consultantProfession !== "Legal Buddy")
    return <PsychologicalQuestions meetingID={meetingID} />;
  else
    return loading ? (
      <Stack justifyContent={"center"} alignItems={"center"} height={"100vh"}>
        <CircularProgress />
      </Stack>
    ) : qNo === -1 ? (
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        height={"100vh"}
        spacing={5}
      >
        <Typography
          fontWeight={700}
          fontSize={{ md: "2rem", xs: "1.5rem" }}
          textAlign={"center"}
        >
          {" "}
          Meeting Questions Answered Successfully
        </Typography>
        <EasyButton
          label="Go To Home"
          size="small"
          width="300px"
          onClick={() => navigate("/v2/client/home")}
        />
      </Stack>
    ) : (
      <Stack
        height={"100vh"}
        sx={{ ...panelPageMargin }}
        justifyContent={"space-between"}
      >
        <Panel />{" "}
        <Stack
          spacing={5}
          margin={"5vw"}
          marginTop={0}
          flexWrap={"wrap"}
          alignItems={"center"}
        >
          <Typography
            fontSize={{
              lg: 35,
              md: 35,
              sm: 25,
              xs: 25,
            }}
          >
            {questions[qNo].q}
          </Typography>
          {questions[qNo].options.length === 0 && (
            <Stack
              direction={{ lg: "row", md: "row", sm: "column", xs: "column" }}
              flexWrap={"wrap"}
              spacing={{ lg: 2, md: 2, sm: 0, xs: 0 }}
              gap={5}
            >
              <Box
                sx={{
                  width: 500,
                  maxWidth: "100%",
                }}
              >
                <TextField
                  fullWidth
                  value={textInput}
                  onChange={(e) => setTextInput(e.target.value)}
                  label="Write your feelings here"
                  id="fullWidth"
                  multiline
                />
              </Box>
              <EasyButton
                size="small"
                label="Submit"
                width={200}
                onClick={() => {
                  if (qNo === questions.length - 1) {
                    submit(textInput);
                    setQNo(-1);
                  } else {
                    setAnswers([
                      ...answers,
                      { question: questions[qNo].q, answer: textInput },
                    ]);
                    setQNo(qNo + 1);
                  }
                  setTextInput("");
                }}
              ></EasyButton>
            </Stack>
          )}
          <Stack
            direction={{ lg: "row", md: "row", sm: "column", xs: "column" }}
            justifyContent={"center"}
            flexWrap={"wrap"}
            spacing={{ lg: 2, md: 2, sm: 0, xs: 0 }}
            gap={5}
          >
            {questions[qNo].options.map((option, index) => {
              return (
                <EasyButton
                  label={option}
                  key={index}
                  size="small"
                  width={option.length > 10 ? null : 200}
                  color={option === "Don’t know" && "orange"}
                  onClick={async () => {
                    if (qNo === questions.length - 1) {
                      submit(option);
                      setQNo(-1);
                    } else {
                      setAnswers([
                        ...answers,
                        { question: questions[qNo].q, answer: option },
                      ]);
                      setQNo(qNo + 1);
                    }
                  }}
                ></EasyButton>
              );
            })}
          </Stack>
        </Stack>
        <Footer />
      </Stack>
    );
}
