import { Box, Button, LinearProgress, Stack, Typography } from "@mui/material";
import { BaseURL, panelPageMargin } from "../../../utils/consts";
import Panel from "../../../components/v2/panel";
import EasyButton2 from "../../../components/v2/easyButton2";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Stress from "../../../utils/stress.jpg";
import EastIcon from "@mui/icons-material/East";
import NeedlePie from "../../../components/v2/needlePie";
import PSSStressGraph from "../../../components/v2/PSSGraph";
const questions = [
  "In the last month, how often have you been upset because of something that happened unexpectedly?",
  "In the last month, how often have you felt that you were unable to control the important things in your life?",
  "In the last month, how often have you felt nervous and stressed?",
  "In the last month, how often have you felt confident about your ability to handle your personal problems?",
  "In the last month, how often have you felt that things were going your way?",
  "In the last month, how often have you found that you could not cope with all the things that you had to do?",
  "In the last month, how often have you been able to control irritations in your life?",
  "In the last month, how often have you felt that you were on top of things?",
  "In the last month, how often have you been angered because of things that happened that were outside of your control?",
  "In the last month, how often have you felt difficulties were piling up so high that you could not overcome them?",
];
function getPredictionMessage(score, setPrediction) {
  const predictionMessages = [
    {
      maxScore: 13,
      severity: "Minimal Stress",
      message:
        "Your stress test results indicate that you are currently experiencing minimal stress levels. This is a positive sign, suggesting that you are managing stress effectively in your daily life. However, it's important to maintain healthy habits and coping strategies to help you stay resilient in the face of future challenges. Our platform offers resources such as consultations with trained psychologists, our AI mental wellness chatbot EasyBuddy, and assessments like the Cognitive Behavioral Therapy (CBT) and depression tests to support your ongoing well-being. Remember, taking proactive steps to manage stress can help you maintain a balanced and fulfilling life.",
    },
    {
      maxScore: 26,
      severity: "Moderate Stress",
      message:
        "Based on your stress test results, it appears that you are experiencing moderate levels of stress. While some stress is a normal part of life, it's important to address and manage it effectively to prevent it from impacting your overall well-being. Our platform offers a range of resources to help you cope with stress, including consultations with trained psychologists, our AI mental wellness chatbot EasyBuddy, and assessments like the Cognitive Behavioral Therapy (CBT) and depression tests. Consider exploring these services for support and guidance, or scheduling a consultation with one of our psychologists who can provide personalized recommendations tailored to your specific needs.",
    },
    {
      maxScore: 40,
      severity: "Severe Stress",
      message:
        "Your stress test results suggest that you are currently experiencing severe levels of stress. We understand that this can feel overwhelming and may be impacting various aspects of your life. It's crucial to prioritize your well-being and take steps to manage your stress effectively. Our platform offers a range of resources to support you, including consultations with trained psychologists who can help you develop a comprehensive stress management plan. Additionally, our AI mental wellness chatbot, EasyBuddy, is available 24/7 to provide immediate support and guidance whenever you need it. You can also benefit from our Cognitive Behavioral Therapy (CBT) and depression assessments for further insights. Remember, seeking help is a sign of strength, and our platform is here to empower you with the tools and support you need to navigate this challenging time.",
    },
  ];

  const predictionMessage = predictionMessages.find(
    (message) => score <= message.maxScore
  );
  setPrediction({
    severity: predictionMessage ? predictionMessage.severity : "No severity",
    message: predictionMessage
      ? predictionMessage.message
      : "No prediction message found for the given score.",
  });
}

export default function StressTracker() {
  const [questionNo, setQuestionNo] = useState(-1);
  const [score, setScore] = useState(0);
  const [prediction, setPrediction] = useState({
    severity: "",
    message: "",
  });
  const [result, setResult] = useState([]);
  const navigate = useNavigate();
  const stressResult = async (newScore) => {
    try {
      const res = await axios.post(
        `${BaseURL}/api/client/stressTracker/stress`,
        {
          score: newScore,
        },
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      setResult(res.data.stressResult);
      if (newScore === -1 && res.data.isTaken === true) {
        setQuestionNo(questions.length);
        setScore(
          res.data.stressResult[res.data?.stressResult?.length - 1].score
        );
        setResult(res.data.stressResult);
        getPredictionMessage(
          res.data.stressResult[res.data?.stressResult?.length - 1].score,
          setPrediction
        );
      } else if (newScore === -1) {
        setQuestionNo(0);
      }
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };
  return (
    <Stack sx={{ ...panelPageMargin }}>
      <Panel />
      {questionNo === -1 && (
        <PSSBlog
          testButton={
            <EasyButton2
              label="Measure My Stress Level"
              width={300}
              onClick={() => stressResult(-1)}
              endIcon={<EastIcon />}
            />
          }
        />
      )}
      {questionNo > -1 && questionNo < questions.length && (
        <Stack
          padding={{ xs: 2, md: 5 }}
          spacing={0}
          border={{ md: 1 }}
          margin={{ xs: 1, md: 5 }}
          borderRadius={5}
          borderColor={{ md: "lightgray" }}
          alignItems={"start"}
        >
          <Typography variant="h5">
            {questionNo + 1}. {questions[questionNo]}
          </Typography>
          <Stack minHeight={300} paddingY={5} spacing={2} alignItems={"center"}>
            {[
              { level: 0, description: "never" },
              { level: 1, description: "almost never" },
              { level: 2, description: "sometimes" },
              { level: 3, description: "fairly often" },
              { level: 4, description: "very often" },
            ].map((level, index) => (
              <AnswerOption
                onClick={() => {
                  let newScore = 0;
                  if ([4, 5, 7, 8].includes(questionNo + 1)) {
                    newScore = score + (4 - level.level);
                  } else {
                    newScore = score + level.level;
                  }
                  if (questionNo === questions.length - 1) {
                    getPredictionMessage(newScore, setPrediction);
                    stressResult(newScore);
                  }
                  setScore(newScore);
                  setQuestionNo(questionNo + 1);
                }}
                key={index}
                level={level.level}
                option={level.description}
              />
            ))}
          </Stack>
          <ProgressIndicator
            questionNo={questionNo + 1}
            totalQuestions={questions.length}
          />
        </Stack>
      )}
      {questionNo === questions.length && (
        <Stack paddingY={{ xs: 2, md: 5 }} px={{ xs: 2, md: 15 }} spacing={0}>
          <Typography
            variant="h4"
            color={"#143F6B"}
            textAlign={"center"}
            mb={5}
          >
            Thank you for taking the stress test!
          </Typography>{" "}
          <Stack alignItems={"center"}>
            <NeedlePie
              score={score}
              keyWidth={"178px"}
              data={[
                { name: "Minimal Stress (0-13)", value: 14, color: "#A8D5E2" },
                {
                  name: "Moderate Stress (13-26)",
                  value: 13,
                  color: "#F9D371",
                },
                { name: "Severe Stress (26-40)", value: 13, color: "#D35D6E" },
              ]}
            />
            <Typography pt={2} variant="h6">
              Today's score is {score}
            </Typography>
            <Typography
              variant="h5"
              textAlign={"center"}
              fontWeight={"bold"}
              mb={5}
              color={
                score >= 0 && score <= 13
                  ? "green"
                  : score <= 26
                  ? "orange"
                  : "red"
              }
            >
              {score >= 0 && score <= 13
                ? "Minimal Stress"
                : score <= 26
                ? "Moderate Stress"
                : "Severe Stress"}{" "}
              Detected
            </Typography>
          </Stack>
          <Stack
            sx={{
              alignItems: "center",
              border: "1px solid #E0E0E0",
              borderRadius: "20px",
              padding: "20px 20px 0 20px",
              color: "#143F6B",
              mb: 5,
              mt: 2,
            }}
          >
            <Typography
              fontWeight={"bold"}
              fontSize={{ md: 20, xs: 19 }}
              sx={{ color: "#662d91", mt: 0, p: 0 }}
              gutterBottom
            >
              Your Today's Wellness Analysis
            </Typography>
            <Typography
              fontSize={{ md: 18, xs: 15 }}
              sx={{
                textAlign: "start",
                pb: 2,
              }}
            >
              {'"' + prediction.message + '"'}
            </Typography>
          </Stack>
          {result && result.length > 1 ? (
            <PSSStressGraph data={result} />
          ) : (
            <Typography variant="h6" sx={{ paddingBottom: 5, pt: 2 }}>
              Thank you for taking the stress test! Take stress test daily to
              unlock more insights on your stress patterns and behaviors. Chat
              with EasyBuddy or consult a professional to lower your stress
              levels and improve well-being.
            </Typography>
          )}
          <Stack
            direction={{ xs: "row" }}
            justifyContent={"end"}
            spacing={{ sm: 2 }}
            gap={1}
            mt={{ xs: 4, md: 5 }}
          >
            <EasyButton2
              label="Easy Buddy"
              width={250}
              onClick={() => {
                navigate("/v2/client/easybuddy");
              }}
            />
            <EasyButton2
              label="Consultants"
              width={250}
              onClick={() => navigate("/v2/client/consultants/false")}
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}
export function AnswerOption({ index, onClick, level, option }) {
  return (
    <Button
      variant="outlined"
      key={index}
      onClick={onClick}
      sx={{
        width: "100%",
        fontWeight: 750,
        borderRadius: 5,
        borderColor: "GrayText",
        color: { xs: "GrayText", md: "#143F6B" },
        textAlign: "start",
        justifyContent: "flex-start",
        "&:hover": {
          backgroundColor: { md: "#143F6B" },
          color: { md: "white" },
          borderColor: { md: "white", xs: "GrayText" },
        },
      }}
    >
      {level + 1}. {option}
    </Button>
  );
}

export function ProgressIndicator({ questionNo, totalQuestions }) {
  return (
    <Stack
      direction={{ md: "row" }}
      alignItems={"center"}
      width={"100%"}
      spacing={2}
      gap={2}
    >
      <Box
        sx={{
          width: "100%",
        }}
      >
        <LinearProgress
          variant="determinate"
          value={(questionNo * 100) / totalQuestions}
        />
      </Box>{" "}
      <Typography variant="h6" sx={{ width: "100px" }}>
        {questionNo} of {totalQuestions}
      </Typography>
    </Stack>
  );
}

export function PSSBlog({ testButton }) {
  return (
    <Stack
      padding={{ xs: 3, md: 5 }}
      spacing={4}
      alignItems={"center"}
      paddingX={{ lg: 5 }}
    >
      <Typography
        fontSize={{ xs: 25, md: 35 }}
        color={"#143F6B"}
        sx={{ textAlign: "center", fontWeight: "bold" }}
      >
        Perceived Stress Scale
      </Typography>
      <Stack
        direction={{ lg: "row" }}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box
          component="img"
          src={Stress}
          sx={{ width: { xs: "100%", md: "50%" } }}
        />
        <Typography
          variant="h6"
          sx={{ width: "100%", paddingLeft: { lg: 15 } }}
        >
          Discover the secrets of your stress with the Perceived Stress Scale
          (PSS). Since its creation in 1983, this simple yet powerful test has
          helped countless people understand their stress levels better. It’s
          like a mirror, reflecting how everyday situations make you feel and
          showing you your stress in a new light. Ready to find out what really
          makes you tick and how to handle stress like a pro? Dive into the PSS
          and start your journey to a calmer, more aware you.
        </Typography>
      </Stack>
      <Stack spacing={5} width={{ md: "100%" }} alignItems={"end"}>
        <Typography variant="h6" sx={{}}>
          The questions in this scale ask about your feelings and thoughts
          during the last month. In each case, you will be asked to indicate how
          often you felt or thought a certain way. Although some of the
          questions are similar, there are differences between them and you
          should treat each one as a separate question. The best approach is to
          answer fairly quickly. That is, don’t try to count up the number of
          times you felt a particular way; rather indicate the alternative that
          seems like a reasonable estimate.
        </Typography>
        <Typography variant="h6" sx={{}}>
          The Perceived Stress Scale is interesting and important because your
          perception of what is happening in your life is most important.
          Consider the idea that two individuals could have the exact same
          events and experiences in their lives for the past month. Depending on
          their perception, total score could put one of those individuals in
          the low stress category and the total score could put the second
          person in the high stress category.
        </Typography>
        <Typography variant="h6" sx={{}}>
          <b>Disclaimer: </b> The Perceived Stress Scale is not a substitute for
          professional medical advice. The scores on the following
          self-assessment do not reflect any particular diagnosis or course of
          treatment. They are meant as a tool to help assess your level of
          stress. If you have any further concerns about your current well
          being, you may contact PeaceTwogether and talk confidentially to one
          of our specialists.
        </Typography>
        {testButton}
      </Stack>
    </Stack>
  );
}
