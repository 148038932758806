import { Stack, Paper, Typography, Button } from "@mui/material";
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { helpLine } from "../../../utils/consts";
import Logo from "../../../components/logo";

export default function MeetingPaymentFailed() {
  let navigate = useNavigate();
  let { meetingID } = useParams();
  useEffect(() => {}, []);
  return (
    <Stack
      height={"100vh"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <div
        style={{
          alignSelf: window.innerWidth > 900 && "flex-end",
          marginRight: window.innerWidth > 900 && 150,
          marginTop: 20,
        }}
      >
        <Logo/>
      </div>
      <Stack
        spacing={5}
        margin={"5vw"}
        marginTop={0}
        flexWrap={"wrap"}
        alignItems={"center"}
      >
        <Typography
          textAlign={"center"}
          fontSize={{
            lg: 40,
            md: 40,
            sm: 25,
            xs: 25,
          }}
        >
          Sorry, your payment failed. <br />
          Booking can't be confirmed without payment. Go to:
        </Typography>
        <Stack
          direction={{ lg: "row", md: "row", sm: "column", xs: "column" }}
          justifyContent={"center"}
          spacing={{ lg: 5, md: 3, sm: 0, xs: 0 }}
          gap={3}
        >
          <Button
            variant="contained"
            sx={{
              bgcolor: "#143F6B",
              "&:hover": {
                backgroundColor: "#662D91",
              },
              fontWeight: 750,
              borderRadius: 2,
            }}
            onClick={() => navigate("/v2/client/home")}
          >
            Go To Home
          </Button>
        </Stack>
      </Stack>
      <Paper
        sx={{
          padding: 5,
          backgroundColor: "#262262",
          color: "whitesmoke",
          alignSelf: "stretch",
        }}
      >
        <Typography
          textAlign={"center"}
          fontSize={{
            lg: 24,
            md: 24,
            sm: 20,
            xs: 20,
          }}
        >
          For technical support, reach out to <br />
          <a style={{ color: "orange" }} href={`tel:${helpLine}`}>
            {helpLine}
          </a>
        </Typography>
      </Paper>
    </Stack>
  );
}
