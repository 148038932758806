import {
  Alert,
  Avatar,
  Box,
  MenuItem,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import Panel from "../../../components/v2/panel";
import { useState } from "react";
import axios from "axios";
import { BaseURL } from "../../../utils/consts";
import { useEffect } from "react";
import EasyButton2 from "../../../components/v2/easyButton2";
import { useRef } from "react";
const company = localStorage.getItem("company");

export default function Settings() {
  const [user, setUser] = useState({});
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState("");
  const fileInputRef = useRef(null);

  const uploadProfilePic = async (file) => {
    try {
      let res = await axios.get(
        `${BaseURL}/api/client/settings/profilePicUrl`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      const buffer = await file.arrayBuffer();
      // each entry of array should contain 8 bits
      const bytes = new Uint8Array(buffer);
      let postPic = await fetch(res.data, {
        method: "PUT",
        headers: {
          "Content-Type": "image/png",
        },
        body: bytes,
      });
      if (postPic.status === 200) {
        await axios.post(
          `${BaseURL}/api/client/settings/profilePicUploadSuccess`,
          {
            url: postPic.url.split("?")[0],
          },
          {
            headers: {
              "x-auth-token": localStorage.getItem("token"),
            },
          }
        );
        localStorage.setItem(
          "pic",
          JSON.stringify({ profilePic: postPic.url.split("?")[0] })
        );
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
      setError(err.response?.data?.message ?? err.message);
    }
  };
  const save = async () => {
    try {
      setError("");
      let res = await axios.post(
        `${BaseURL}/api/client/settings/editClient`,
        {
          ...user,
        },
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      setUser(res.data);
      setEdit(false);
    } catch (err) {
      console.log(err.message);
      setError(err.response?.data?.message ?? err.message);
    }
  };
  const fetchData = async () => {
    try {
      setError("");
      let res = await axios.get(`${BaseURL}/api/client/settings/profile`, {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      });
      setUser(res.data.user);
    } catch (err) {
      console.log(err.message);
      setError(err.response?.data?.message ?? err.message);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Box sx={{ display: "flex" }}>
      <Panel />
      <Box component="main" sx={{ flexGrow: 1, padding: 3 }}>
        {" "}
        <Toolbar />
        <Typography
          fontSize={{ lg: 35, xs: 25 }}
          fontWeight={"bold"}
          padding={2}
        >
          Settings
        </Typography>
        <Stack sx={{ margin: 2 }} alignItems={"center"} spacing={5}>
          <Avatar
            src={user?.photoLink}
            alt="Profile Pic"
            sx={{ width: 150, height: 150, cursor: "pointer" }}
            onClick={() => fileInputRef.current.click()}
          />{" "}
          <input
            ref={fileInputRef}
            hidden
            accept="image/*"
            type="file"
            onChange={(e) => uploadProfilePic(e.target.files[0])}
          />
          <Stack
            direction={{ lg: "row", md: "row", sm: "column", xs: "column" }}
            sx={{
              "& > *:not(:first-of-type)": {
                marginLeft: "0",
              },
            }}
            spacing={5}
            flexWrap={"wrap"}
            gap={5}
            alignItems={"center"}
          >
            <TextField
              label="Full name"
              value={user?.name || ""}
              style={{ width: 300 }}
              onChange={(e) =>
                !company && edit && setUser({ ...user, name: e.target.value })
              }
              variant="outlined"
              margin="normal"
            />
            <TextField
              label="Username"
              value={user?.username || ""}
              style={{ width: 300 }}
              onChange={(e) =>
                edit && setUser({ ...user, username: e.target.value })
              }
              variant="outlined"
              margin="normal"
            />
            <TextField
              label="Email"
              value={user?.email || ""}
              style={{ width: 300 }}
              onChange={(e) =>
                !company && edit && setUser({ ...user, email: e.target.value })
              }
              variant="outlined"
              margin="normal"
            />
            <TextField
              label="Phone no."
              value={user?.phoneNo || ""}
              style={{ width: 300 }}
              variant="outlined"
              margin="normal"
            />
            <TextField
              label="Age"
              value={user?.age || ""}
              style={{ width: 300 }}
              onChange={(e) =>
                edit && setUser({ ...user, age: e.target.value })
              }
              variant="outlined"
              margin="normal"
            />
            <TextField
              label="Gender"
              select={edit}
              value={user?.gender || ""}
              style={{ width: 300 }}
              onChange={(e) =>
                edit && setUser({ ...user, gender: e.target.value })
              }
              variant="outlined"
              margin="normal"
            >
              <MenuItem value={"Male"}>Male</MenuItem>
              <MenuItem value={"Female"}>Female</MenuItem>
              <MenuItem value={"Other"}>Other</MenuItem>
            </TextField>
            <TextField
              label="Marital Status"
              select={edit}
              value={user?.isMarried ? "Yes" : "No" || ""}
              style={{ width: 300 }}
              onChange={(e) =>
                edit &&
                setUser({ ...user, isMarried: e.target.value === "Yes" })
              }
              variant="outlined"
              margin="normal"
            >
              <MenuItem value={"Yes"}>Yes</MenuItem>
              <MenuItem value={"No"}>No</MenuItem>
            </TextField>
            {user?.corporate?.location && (
              <TextField
                label="Location"
                value={user?.corporate?.location}
                style={{ width: 300 }}
                variant="outlined"
                margin="normal"
              />
            )}
            {company ? (
              <TextField
                label="Department"
                value={user?.corporate?.department || ""}
                style={{ width: 300 }}
                variant="outlined"
                margin="normal"
              />
            ) : (
              <TextField
                value={"India"}
                style={{ width: 300 }}
                variant="outlined"
                margin="normal"
              />
            )}
          </Stack>
          {error && <Alert severity="error">{error}</Alert>}
          <Stack marginTop={"24px"}>
            {!edit ? (
              <EasyButton2
                label="Edit Profile"
                width="200px"
                onClick={() => setEdit(true)}
              ></EasyButton2>
            ) : (
              <Stack
                direction={{ sm: "row" }}
                gap={2}
                minWidth={{ sm: "100vw", lg: "100px" }}
                justifyContent={{
                  lg: "flex-end",
                  md: "center",
                  sm: "center",
                }}
                spacing={3}
              >
                <EasyButton2
                  label="Save"
                  width="200px"
                  onClick={save}
                ></EasyButton2>
                <EasyButton2
                  label="Cancel"
                  width="200px"
                  onClick={() => {
                    fetchData();
                    setEdit(false);
                  }}
                ></EasyButton2>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
}
