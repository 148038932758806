import { PieChart, Pie, Cell } from "recharts";
import { Stack } from "@mui/material";

const CustomLegend = ({ payload, keyWidth }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        gap: "5px",
        textAlign:'start'
      }}
    >
      {payload?.map((entry, index) => (
        <div
          key={`item-${index}`}
          style={{
            display: "flex",
            alignItems: "center",
            //marginRight: "20px",
            gap: "5px",
            // flexDirection: "column",
          }}
        >
          <div
            style={{
              width: "15px",
              height: "15px",
              backgroundColor: entry.color,
              marginRight: "5px",
              borderRadius: "50%",
              boxShadow: "0px 0px 5px 0px rgba(1,0,0,0.1)",
            }}
          ></div>
          <span
            style={{
              color: "GrayText",
              width: keyWidth ?? "220px",
              paddingLeft: "1px",
            }}
          >
            {entry.name}
          </span>
        </div>
      ))}
    </div>
  );
};
const RADIAN = Math.PI / 180;

const cx = 125;
const cy = 120;
const iR = 80;
const oR = 120;

const needle = (value, data, cx, cy, iR, oR, color) => {
  let total = 0;
  data.forEach((v) => {
    total += v.value;
  });
  const ang = 180.0 * (1 - value / total);
  const length = (iR + 2 * oR) / 3;
  const sin = Math.sin(-RADIAN * ang);
  const cos = Math.cos(-RADIAN * ang);
  const r = 5;
  const x0 = cx + 5;
  const y0 = cy + 5;
  const xba = x0 + r * sin;
  const yba = y0 - r * cos;
  const xbb = x0 - r * sin;
  const ybb = y0 + r * cos;
  const xp = x0 + length * cos;
  const yp = y0 + length * sin;

  return [
    <circle cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
    <path
      d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`}
      stroke="#none"
      fill={color}
    />,
  ];
};

export default function NeedlePie({ score, data, keyWidth }) {
  return (
    <Stack
      direction={keyWidth ? {sm:"row"} : { xs: "column", md: "row" }}
      gap={2}
      justifyContent={"center"}
      alignItems={"center"}
      spacing={2}
    >
      <PieChart width={260} height={140}>
        <Pie
          dataKey="value"
          startAngle={180}
          endAngle={0}
          data={data}
          cx={cx}
          cy={cy}
          innerRadius={iR}
          outerRadius={oR}
          fill="#8884d8"
          stroke="none"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        {needle(score, data, cx, cy, iR, oR, "#2E86AB ")}
      </PieChart>{" "}
      <CustomLegend payload={data} keyWidth={keyWidth} />
    </Stack>
  );
}
