import { Box, Stack, Typography } from "@mui/material";
import Panel from "../../../components/v2/panel";
import { BaseURL, panelPageMargin } from "../../../utils/consts";
import EasyButton2 from "../../../components/v2/easyButton2";
import { useState } from "react";
import axios from "axios";
import { Loader } from "../../../components/Loader";
import PersonalityTest from "../../../utils/personality_test.jpg";
import EastIcon from "@mui/icons-material/East";
import { useNavigate } from "react-router-dom";
import { AnswerOption, ProgressIndicator } from "./stressTracker";
import NeedlePie from "../../../components/v2/needlePie";
import DepressionGraph from "../../../components/v2/depressionGraph";
const questions = [
  {
    question: "Sadness",
    options: [
      "I do not feel sad.",
      "I feel sad much of the time.",
      "I am sad all the time.",
      "I am so sad or unhappy that I can't stand it.",
    ],
  },
  {
    question: "Pessimism",
    options: [
      "I am not discouraged about my future.",
      "I feel more discouraged about my future than I used to be.",
      "I do not expect things to work out for me.",
      "I feel my future is hopeless and will only get worse.",
    ],
  },
  {
    question: "Past Failure",
    options: [
      "I do not feel like a failure.",
      "I have failed more than I should have.",
      "As I look back, I see a lot of failures.",
      "I feel I am a total failure as a person.",
    ],
  },
  {
    question: "Loss of Pleasure",
    options: [
      "I get as much pleasure as I ever did from the things I enjoy.",
      "I don't enjoy things as much as I used to.",
      "I get very little pleasure from the things I used to enjoy.",
      "I can't get any pleasure from the things I used to enjoy.",
    ],
  },
  {
    question: "Guilty Feelings",
    options: [
      "I don't feel particularly guilty.",
      "I feel guilty over many things I have done or should have done.",
      "I feel quite guilty most of the time.",
      "I feel guilty all of the time.",
    ],
  },
  {
    question: "Punishment Feelings",
    options: [
      "I don’t feel I am being punished.",
      "I feel I may be punished.",
      "I expect to be punished.",
      "I feel I am being punished.",
    ],
  },
  {
    question: "Self-Dislike",
    options: [
      "I feel the same about myself as ever.",
      "I have lost confidence in myself.",
      "I am disappointed in myself.",
      "I dislike myself.",
    ],
  },
  {
    question: "Self-Criticalness",
    options: [
      "I don’t criticize or blame myself more than usual.",
      "I am more critical of myself than I used to be.",
      "I criticize myself for all of my faults.",
      "I blame myself for everything bad that happens.",
    ],
  },
  {
    question: "Suicidal Thoughts or Wishes",
    options: [
      "I don’t have any thoughts of killing myself.",
      "I have thoughts of killing myself, but I would not carry them out.",
      "I would like to kill myself.",
      "I would kill myself if I had the chance.",
    ],
  },
  {
    question: "Crying",
    options: [
      "I don’t cry anymore than I used to.",
      "I cry more than I used to.",
      "I cry over every little thing.",
      "I feel like crying, but I can’t.",
    ],
  },
  {
    question: "Agitation",
    options: [
      "I am no more restless or wound up than usual.",
      "I feel more restless or wound up than usual.",
      "I am so restless or agitated that it’s hard to stay still.",
      "I am so restless or agitated that I have to keep moving or doing something.",
    ],
  },
  {
    question: "Loss of Interest",
    options: [
      "I have not lost interest in other people or activities.",
      "I am less interested in other people or things than before.",
      "I have lost most of my interest in other people or things.",
      "It’s hard to get interested in anything.",
    ],
  },
  {
    question: "Indecisiveness",
    options: [
      "I make decisions about as well as ever.",
      "I find it more difficult to make decisions than usual.",
      "I have much greater difficulty in making decisions than I used to.",
      "I have trouble making any decisions.",
    ],
  },
  {
    question: "Worthlessness",
    options: [
      "I do not feel I am worthless.",
      "I don’t consider myself as worthwhile and useful as I used to.",
      "I feel more worthless as compared to other people.",
      "I feel utterly worthless.",
    ],
  },
  {
    question: "Loss of Energy",
    options: [
      "I have as much energy as ever.",
      "I have less energy than I used to have.",
      "I don’t have enough energy to do very much.",
      "I don’t have enough energy to do anything.",
    ],
  },
  {
    question: "Changes in Sleeping Pattern",
    options: [
      "I have not experienced any change in my sleeping pattern.",
      "I sleep somewhat more than usual.",
      "I sleep somewhat less than usual.",
      "I sleep a lot more than usual.",
      "I sleep a lot less than usual.",
      "I sleep most of the day.",
      "I wake up 1-2 hours early and can't get back to sleep.",
    ],
  },
  {
    question: "Irritability",
    options: [
      "I am no more irritable than usual.",
      "I am more irritable than usual.",
      "I am much more irritable than usual.",
      "I am irritable all the time.",
    ],
  },
  {
    question: "Changes in Appetite",
    options: [
      "I have not experienced any change in my appetite.",
      "My appetite is somewhat less than usual.",
      "My appetite is somewhat greater than usual.",
      "My appetite is much less than before.",
      "My appetite is much greater than usual.",
      "I have no appetite at all.",
      "I crave food all the time.",
    ],
  },
  {
    question: "Concentration Difficulty",
    options: [
      "I can concentrate as well as ever.",
      "I can’t concentrate as well as usual.",
      "It’s hard to keep my mind on anything for very long.",
      "I find I can’t concentrate on anything.",
    ],
  },
  {
    question: "Tiredness or Fatigue",
    options: [
      "I am no more tired or fatigued than usual.",
      "I get more tired or fatigued more easily than usual.",
      "I am too tired or fatigued to do a lot of the things I used to do.",
      "I am too tired or fatigued to do most of the things I used to do.",
    ],
  },
  {
    question: "Loss of Interest in Sex",
    options: [
      "I have not noticed any recent change in my interest in sex.",
      "I am less interested in sex than I used to be.",
      "I am much less interested in sex now.",
      "I have lost interest in sex completely.",
    ],
  },
];
function getPredictionMessage(score, setPrediction) {
  const predictionMessages = [
    {
      maxScore: 13,
      severity: "Minimal Depression",
      message:
        "Your depression test results indicate that you are currently experiencing minimal symptoms of depression. While it's great that you're not facing significant challenges at the moment, our platform offers resources to help you maintain your mental well-being. Consider exploring our AI mental wellness chatbot, EasyBuddy, for support or trying out our Cognitive Behavioral Therapy (CBT) and stress assessments for further insights. Remember, prioritizing your mental health is always important, and our platform is here to support you every step of the way.",
    },
    {
      maxScore: 19,
      severity: "Mild Depression",
      message:
        "Your depression test results suggest that you are experiencing mild symptoms of depression. While these symptoms may not be significantly interfering with your daily life, our platform offers various resources to help you manage them effectively. Our AI mental wellness chatbot, EasyBuddy, is available 24/7 to provide support and guidance whenever you need it. Additionally, our platform offers consultations with trained psychologists who can help you develop a personalized plan to address your specific needs. You can also benefit from our Cognitive Behavioral Therapy (CBT) and stress assessments for further insights.",
    },
    {
      maxScore: 28,
      severity: "Moderate Depression",
      message:
        "Based on your depression test results, it appears that you are experiencing moderate symptoms of depression. These symptoms may be impacting various aspects of your life, and it's important to take action to manage them. Our platform offers a range of resources to support you, including consultations with trained psychologists who can provide you with personalized guidance and help you develop effective coping strategies. Remember, you don't have to face these challenges alone – our AI mental wellness chatbot, EasyBuddy, is available 24/7 to provide support and guidance whenever you need it. You can also benefit from our Cognitive Behavioral Therapy (CBT) and stress assessments for further insights.",
    },
    {
      maxScore: 63,
      severity: "Severe Depression",
      message:
        "Your depression test results indicate that you are currently experiencing severe symptoms of depression. We understand that these symptoms can feel overwhelming, but our platform is here to support you every step of the way. We strongly recommend scheduling a consultation with one of our trained psychologists who can provide you with personalized guidance and help you develop a comprehensive treatment plan. In addition to consultations, our AI mental wellness chatbot, EasyBuddy, is available 24/7 to provide immediate support and guidance. You can also benefit from our Cognitive Behavioral Therapy (CBT) and stress assessments for further insights. Remember, seeking help is a sign of strength, and our platform is here to empower you with the tools and support you need to overcome these challenges.",
    },
  ];

  const predictionMessage = predictionMessages.find(
    (message) => score <= message.maxScore
  );
  setPrediction({
    severity: predictionMessage ? predictionMessage.severity : "No severity",
    message: predictionMessage
      ? predictionMessage.message
      : "No prediction message found for the given score.",
  });
}
export default function DepressionTracker() {
  const [questionNo, setQuestionNo] = useState(-1);
  const [score, setScore] = useState(0);
  const [result, setResult] = useState({});
  const [loading, setLoading] = useState(false);
  const [prediction, setPrediction] = useState({ message: "", severity: "" });
  const navigate = useNavigate();
  const depressionResult = async (newScore) => {
    try {
      setLoading(true);
      const res = await axios.post(
        `${BaseURL}/api/client/depressionTracker/depression`,
        {
          score: newScore,
        },
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      setResult(res.data.depression);
      if (newScore === -1 && res.data.isTaken === true) {
        setQuestionNo(questions.length);
        setScore(res.data.depression[res.data.depression.length - 1].score);
        getPredictionMessage(
          res.data.depression[res.data.depression.length - 1].score,
          setPrediction
        );
      } else if (newScore === -1) {
        setQuestionNo(0);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      alert(err.message);
      setLoading(false);
    }
  };
  if (loading) {
    return <Loader />;
  }
  return (
    <Stack sx={{ ...panelPageMargin, height: "100%" }}>
      <Panel />
      {questionNo === -1 && (
        <BDIBlog
          testButton={
            <EasyButton2
              label="Track My Depression Level"
              endIcon={<EastIcon />}
              width={300}
              onClick={() => {
                depressionResult(-1);
              }}
            />
          }
        />
      )}
      {questionNo >= 0 && questionNo < questions.length && (
        <Stack
          padding={{ md: 5, xs: 2 }}
          spacing={5}
          border={{ md: 1 }}
          margin={{ md: 5, xs: 2 }}
          borderRadius={5}
          borderColor={{ md: "lightgray" }}
          alignItems={"start"}
        >
          <Typography variant="h5">
            {questionNo + 1}. {questions[questionNo].question}
          </Typography>
          <Stack spacing={2}>
            {questions[questionNo].options.map((option, index) => (
              <AnswerOption
                key={index}
                level={index}
                option={option}
                onClick={() => {
                  const optionIndex =
                    questions[questionNo].options.indexOf(option);
                  let newScore = 0;
                  if (questions[questionNo].options.length === 7) {
                    newScore =
                      (optionIndex === 0
                        ? 0
                        : optionIndex <= 2
                        ? 1
                        : optionIndex <= 4
                        ? 2
                        : 3) + score;
                  } else {
                    newScore = optionIndex + score;
                  }
                  if (questionNo === questions.length - 1) {
                    getPredictionMessage(newScore, setPrediction);
                    depressionResult(newScore);
                  }
                  setScore(newScore);
                  setQuestionNo(questionNo + 1);
                }}
              />
            ))}
          </Stack>
          <ProgressIndicator
            questionNo={questionNo + 1}
            totalQuestions={questions.length}
          />
        </Stack>
      )}
      {questionNo === questions.length && (
        <Stack paddingY={{ md: 5, xs: 2 }} px={{ md: 15, xs: 2 }} spacing={4}>
          <Typography
            variant="h4"
            color={"#143F6B"}
            textAlign={"center"}
            mb={5}
          >
            Thank you for taking the depression test!
          </Typography>
          <Stack alignItems={"center"}>
            <NeedlePie
              score={score}
              data={[
                {
                  name: "Minimal Depression (0-13)",
                  value: 14,
                  color: "#A8D5E2",
                },
                {
                  name: "Mild Depression (14-19)",
                  value: 6,
                  color: "#F9D371",
                },
                {
                  name: "Moderate Depression (20-28)",
                  value: 7,
                  color: "#F28705 ",
                },
                {
                  name: "Severe Depression (29-63)",
                  value: 34,
                  color: "#D35D6E",
                },
              ]}
            />
            <Typography pt={2} variant="h6">
              Today's score is {score}
            </Typography>
            <Typography
              variant="h5"
              fontWeight={"bold"}
              textAlign={"center"}
              color={
                score >= 0 && score <= 14
                  ? "green"
                  : score <= 19
                  ? "yellowgreen"
                  : score <= 26
                  ? "orange"
                  : "red"
              }
            >
              {score >= 0 && score <= 9
                ? "Minimal Depression"
                : score <= 18
                ? "Mild Depression"
                : score <= 29
                ? "Moderate Depression"
                : "Severe Depression"}{" "}
              Detected
            </Typography>
          </Stack>
          <Stack
            sx={{
              alignItems: "center",
              border: "1px solid #E0E0E0",
              borderRadius: "20px",
              padding: "20px 20px 0 20px",
              color: "#143F6B",
              mb: 5,
              mt: 2,
            }}
          >
            <Typography
              fontWeight={"bold"}
              fontSize={{ md: 20, xs: 19 }}
              sx={{ color: "#662d91", mt: 0, p: 0 }}
              gutterBottom
            >
              Your Today's Wellness Analysis
            </Typography>
            <Typography
              fontSize={{ md: 18, xs: 15 }}
              sx={{
                textAlign: "start",
                pb: 2,
              }}
            >
              {'"' + prediction.message + '"'}
            </Typography>
          </Stack>
          <DepressionGraph data={result} />
          <Stack
            direction={{ sm: "row" }}
            justifyContent={"end"}
            spacing={2}
            gap={2}
          >
            <EasyButton2
              label="Talk to Easy Buddy"
              width={{ sm: 250 }}
              onClick={() => {
                navigate("/v2/client/easybuddy");
              }}
            />
            <EasyButton2
              label="Consult Psychologist"
              width={{ sm: 250 }}
              onClick={() => navigate("/v2/client/consultants/false")}
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}

export function BDIBlog({ testButton }) {
  return (
    <Stack
      padding={{ xs: 2, md: 5 }}
      spacing={4}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Typography
        fontSize={{ xs: 25, md: 35 }}
        color={"#143F6B"}
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          paddingBottom: { md: 5 },
        }}
      >
        Depression Tracker - BDI (Beck Depression Inventory)
      </Typography>
      <Stack
        spacing={2}
        gap={2}
        alignItems={{ xs: "center", md: "start" }}
        direction={{ md: "row" }}
      >
        <Box
          component="img"
          src={PersonalityTest}
          sx={{ width: { md: "50%", xs: "100%" } }}
        />
        <Stack
          spacing={5}
          paddingX={{ md: 5 }}
          width={{ md: "100%" }}
          alignItems={"end"}
        >
          <Typography variant="h6">
            The Beck Depression Inventory (BDI) is a powerful and insightful
            tool designed to help individuals understand and measure the
            severity of their depressive symptoms. Whether you're experiencing a
            rough patch, feeling down without knowing why, or just curious about
            your emotional well-being, the BDI offers a structured and reliable
            way to assess your state of mind.
          </Typography>
          <Typography variant="h6">
            {" "}
            Depression is a condition that affects millions worldwide, but its
            symptoms can vary greatly from person to person. This is where the
            BDI comes in—it provides a nuanced approach to identifying the
            specific aspects of depression that may be affecting you. Through a
            series of carefully formulated questions, the BDI captures a wide
            range of emotional, physical, and cognitive symptoms associated with
            depression, from sadness and loss of pleasure to changes in sleep
            patterns and appetite.
          </Typography>
          <Typography variant="h6">
            Taking the BDI can be a step towards self-awareness and
            understanding. It's a chance to take a moment for yourself, to
            reflect on your feelings and experiences. The insights gained from
            this inventory can empower you to seek the appropriate support and
            make informed decisions about your mental health journey.
          </Typography>
          <Typography variant="h6">
            Moreover, the BDI is widely used by healthcare professionals and
            therapists, making it a trusted resource in both personal and
            clinical settings. Whether you're considering therapy, already in
            treatment, or just looking for clarity on your emotional state, the
            Beck Depression Inventory can be a valuable tool in your mental
            health toolkit.
          </Typography>
          <Typography variant="h6">
            Embarking on the BDI is more than just taking a test; it's an
            investment in your well-being and a step towards understanding
            yourself better. Remember, recognizing and acknowledging how you
            feel is the first step towards healing and growth.
          </Typography>
          <Typography variant="h6">
            <b>Instructions: </b> This questionnaire consists of 21 groups of
            statements. Please read each group of statements carefully, and then
            pick out the one statement in each group that best describes the way
            you have been feeling during the past two weeks, including today. If
            several statements in the group seem to apply equally well, pick the
            highest number for that group
          </Typography>
          {testButton}
        </Stack>
      </Stack>
    </Stack>
  );
}
