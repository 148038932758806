import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { Alert, Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ReviewCard from "../../../components/reviewCard";
import { FixedSizeList as List } from "react-window";
import { useParams } from "react-router-dom";
import axios from "axios";
import { BaseURL, emptyString, panelPageMargin } from "../../../utils/consts";
import { useEffect, useState } from "react";
import Panel from "../../../components/v2/panel";
import NewConsultantCard from "../../../components/v2/consultantCard";
import NewFooter from "../../../components/v2/footer";

export default function ConsultantDetails() {
  const [consultant, setConsultant] = useState({ specialist: {}, reviews: [] });
  const [error, setError] = useState("");
  let { consultantId } = useParams();
  const navigate = useNavigate();
  const font = { lg: 20, md: 20, sm: 15, xs: 15 };
  const Row = ({ index, style }) => (
    <div style={style}>
      <ReviewCard
        clientName={consultant.reviews[index].client.username}
        clientPic={consultant.reviews[index].client.photoLink}
        review={consultant.reviews[index].clientReview}
      />
    </div>
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        let res = await axios.get(
          `${BaseURL}/api/client/getDetails/${consultantId}`,
          {
            headers: {
              "x-auth-token": localStorage.getItem("token"),
            },
          }
        );
        setConsultant(res.data);
      } catch (err) {
        console.log(err);
        setError(err.response?.data?.message ?? err.message);
      }
    };
    fetchData();
  }, [consultantId]);
  return (
    <Stack spacing={2} minHeight={"100vh"} sx={{ ...panelPageMargin }}>
      <Panel />
      <Stack sx={{ flex: "1 0 auto" }} spacing={4} paddingX={{ xs: 3, md: 5 }} pb={5}>
        {error && <Alert severity="error">{error}</Alert>}
        <Stack
          direction={{ lg: "row", md: "row", sm: "column", xs: "column" }}
          spacing={2}
          marginTop={0}
          justifyContent={"center"}
          alignItems={{ lg: "start", md: "start", xs: "center" }}
        >
          <NewConsultantCard consultant={consultant.specialist} />
          <Stack
            spacing={4}
            alignItems={"flex-end"}
            pl={{ lg: 5, md: 5 }}
            pt={0}
            flexWrap={"wrap"}
            justifyContent={"space-around"}
          >
            <Box
              // width={{ lg: "50vw", md: "40vw", sm: "90vw", xs: "90vw" }}
              sx={{
                fontSize: font,
                padding: 3,
                border: "darkblue 1px solid",
                borderRadius: 2,
                alignSelf: "stretch",
              }}
            >
              <Typography
                sx={{
                  wordWrap: "break-word",
                  width: "100%",
                }}
              >
                <b>About: </b>
                {consultant.specialist.about ?? emptyString}
              </Typography>
            </Box>
            <Stack
              alignSelf={"flex-start"}
              sx={{ fontSize: 20 }}
              maxWidth={{ lg: "65vw", sm: "50vw", xs: "90vw" }}
              spacing={0.5}
              direction={"row"}
              flexWrap={"wrap"}
              gap={2}
            >
              <Chip
                label={
                  <Typography variant="body2">
                    <b>Profession: </b>
                    {consultant.specialist.profession}
                  </Typography>
                }
                sx={{
                  fontSize: font,
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                }}
                variant="outlined"
              />
              <Chip
                label={
                  <Typography variant="body2">
                    <b>Specialisation: </b>
                    {consultant.specialist.specialisation}
                  </Typography>
                }
                sx={{ fontSize: font }}
                variant="outlined"
              />
              <Chip
                label={
                  <Typography variant="body2">
                    <b>Education: </b>
                    {consultant.specialist.education}
                  </Typography>
                }
                sx={{ fontSize: font }}
                variant="outlined"
              />
              <Chip
                label={
                  <Typography variant="body2">
                    <b>Experience: </b>
                    {consultant.specialist.experience}
                  </Typography>
                }
                sx={{ fontSize: font }}
                variant="outlined"
              />
              <Chip
                label={
                  <Typography variant="body2">
                    <b>Award: </b>
                    {consultant.specialist.awardsnRecognition}
                  </Typography>
                }
                sx={{ fontSize: font }}
                variant="outlined"
              />
              <Chip
                label={
                  <Typography variant="body2">
                    <b>Location: </b>
                    {consultant.specialist.location}
                  </Typography>
                }
                sx={{ fontSize: font }}
                variant="outlined"
              />
              <Chip
                label={
                  <Typography variant="body2">
                    <b>Spoken Languages: </b>
                    {consultant.specialist.spokenLanguages?.join(", ")}
                  </Typography>
                }
                sx={{ fontSize: font }}
                variant="outlined"
              />
            </Stack>
            <div sx={{ alignSelf: "flex-end" }}>
              <Button
                variant="contained"
                sx={{
                  bgcolor: "#143F6B",
                  "&:hover": {
                    backgroundColor: "#662D91",
                  },
                  fontWeight: 750,
                  borderRadius: 2,
                }}
                onClick={() =>
                  navigate(`/v2/client/booking/${consultant?.specialist._id}`, {
                    state: { ...consultant.specialist },
                  })
                }
              >
                Book Appointment
              </Button>
            </div>
          </Stack>
        </Stack>
        <Stack paddingLeft={{ md: 35 }} spacing={3}>
          <Typography variant="h5">
            User Reviews ({consultant.reviews.length})
          </Typography>
          {consultant.reviews.length === 0 ? (
            <Stack>
              <Typography
                py={2}
                variant="h5"
                textAlign={"center"}
                color={"grey"}
              >
                No reviews received so far...
              </Typography>
            </Stack>
          ) : (
            <List
              height={200}
              itemCount={consultant.reviews.length}
              itemSize={200}
            >
              {Row}
            </List>
          )}
        </Stack>
      </Stack>
      <NewFooter isLegal={consultant.specialist.profession === "Legal Buddy"} />
    </Stack>
  );
}
