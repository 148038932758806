import { Typography, useMediaQuery } from "@mui/material";
import { useState, useEffect, Fragment } from "react";

export default function BuddyGreeting({ fullText }) {
  const [greetingIndex, setGreetingIndex] = useState(0);
  const isSmallScreen = useMediaQuery("(max-width:1260px)");
  const uniqueId = "gradientTextUniqueId";

  useEffect(() => {
    if (greetingIndex < fullText.length) {
      const timeoutId = setTimeout(() => {
        // Find the index of the next space after the current greetingIndex or the end of the string
        let nextSpaceIndex = fullText.indexOf(" ", greetingIndex + 1);
        if (nextSpaceIndex === -1) nextSpaceIndex = fullText.length; // If no more spaces, go to the end of the text
        setGreetingIndex(nextSpaceIndex + 1);
      }, 100); // Adjust typing speed with the timeout value. Here, 100ms.

      return () => clearTimeout(timeoutId);
    }
  }, [greetingIndex, fullText]);
  return (
    <>
      {" "}
      <style>
        {`
          .${uniqueId} {
            background: linear-gradient(to right, #4285f4, #9b72cb,#d96570);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            font-weight: 700;
            font-size: 30px;
          }
        `}
      </style>
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          textAlign: "start",
          fontWeight: "medium",
          color: "gray",
          paddingBottom: 5,
        }}
      >
        <span
          style={{ fontSize: isSmallScreen ? "40px" : "60px" }}
          className={uniqueId}
        >
          Hi {localStorage.getItem("username")}
        </span>
        <br />
        {fullText
          .substring(0, greetingIndex)
          .split("\n")
          .map((item, key) => (
            <Fragment key={key}>
              {item}
              {!isSmallScreen && <br />}{" "}
            </Fragment>
          ))}
      </Typography>
    </>
  );
}
