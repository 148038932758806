import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { helpLine, panelPageMargin } from "../../../utils/consts";
import Panel from "../../../components/v2/panel";
import EasyButton2 from "../../../components/v2/easyButton2";

export default function NewServices() {
  const navigate = useNavigate();

  return (
    <Stack
      justifyContent={"center"}
      sx={{ ...panelPageMargin }}
    >
      <Panel />
      <Stack
        spacing={"30vh"}
        margin={2}
        paddingTop={{ xs: "10vh", lg: "20vh" }}
        flexWrap={"wrap"}
        justifyContent={"space-between"}
        alignItems={"center"}
        paddingX={{ md: 10 }}
      >
        <Stack spacing={5}>
          <Typography
            textAlign={"center"}
            fontSize={{
              lg: 30,
              md: 30,
              sm: 25,
              xs: 25,
            }}
          >
            It’s your struggle, but you don’t need to face it alone. You can
            have a private conversation with an expert for your emotional
            guidance and support.
          </Typography>
          <Stack
            direction={{ md: "row" }}
            justifyContent={"center"}
            alignItems={"center"}
            spacing={{ lg: 5, md: 3, sm: 0, xs: 0 }}
            gap={3}
          >
            <EasyButton2
              label="Legal Guidance"
              width="300px"
              onClick={() => {
                navigate(`/v2/client/consultants/${true}`);
              }}
            ></EasyButton2>
            <EasyButton2
              label="General Guidance"
              width="300px"
              onClick={() => {
                navigate(`/v2/client/consultants/${false}`);
              }}
            ></EasyButton2>
          </Stack>
        </Stack>
        <Typography
          textAlign={"center"}
          fontSize={{
            lg: 24,
            md: 24,
            sm: 20,
            xs: 20,
          }}
          sx={{
            color: "gray",
            paddingY: 2,
          }}
        >
          Will you like to have an emotional support call with our EasyBuddy to
          talk your heart out? Don’t worry, your call will be completely
          anonymous. It’s free:
          <a href={`tel:${helpLine}`}>{helpLine}</a>
        </Typography>
      </Stack>
    </Stack>
  );
}
