import React, { useEffect, useRef } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Stack, Typography } from "@mui/material";

export default function BarGraph(props) {
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    // Scroll to the end
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft =
        scrollContainerRef.current.scrollWidth;
    }
  }, []);

  return (
    <Stack
      sx={{
        width: "100%",
        height: { md: "343px", xs: "500px" },
        backgroundColor: "white",
        borderRadius: "10px",
        overflow: "hidden", // Hide any overflow from the main container
        border: "1px solid lightgray",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 15px;",
      }}
    >
      <Typography sx={{ textAlign: "center",fontWeight:"bold", padding: 2, width: "100%" }} >{props.title}</Typography>
      <div
        ref={scrollContainerRef}
        style={{
          width: "100%",
          height: "calc(100% - 0px)", // Adjust height to exclude the title height
          overflowX: "auto", // Allow horizontal scrolling
          scrollbarWidth: "thin", // For Firefox
          msOverflowStyle: "none", // For Internet Explorer and Edge
        }}
      >
        <style>
          {`
          /* For Chrome, Safari, and Opera */
          div::-webkit-scrollbar {
            height: 2px;
          }

          div::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 10px;
          }

          div::-webkit-scrollbar-thumb:hover {
            background: #555;
          }
        `}
        </style>
        <div style={{ width: "4000px", height: "90%" }}>
          {/* Set a large width for the chart */}
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={props.data}
              margin={{
                top: 0,
                right: 30,
                left: 0,
                bottom: 0,
              }}
              barSize={100}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="_id"
                interval={20}
                tick={{ fontSize: "10px", color: "gray" }}
              />
              <YAxis
                tick={{ fontSize: "10px", color: "gray" }}
                domain={[0, props.maxSignup+2]}
              />
              <Tooltip />
              <Legend
                verticalAlign="top"
                align="right"
                iconType="circle"
                iconSize={10}
                formatter={(value) => (
                  <span style={{ fontSize: "12px", color: "gray" }}>
                    {value}
                  </span>
                )}
              />
              <Bar dataKey="Count" fill="#8884d8" label={{ position: "top" }} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </Stack>
  );
}
