import axios from "axios";
import logo from "./peace_logo.png";
import { BaseURL } from "./consts";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

export async function RazorpayCheckout({
  amount,
  id: order_id,
  currency,
  receipt,
  client,
}) {
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

  if (!res) {
    alert("Razorpay SDK failed to load. Are you online?");
    return;
  }

  const options = {
    key: "rzp_live_NZuSHtxHB2h1Wz", // Enter the Key ID generated from the Dashboard
    amount: amount.toString(),
    currency: currency,
    name: "EasyTwogether",
    description:
      "Thank you for choosing EasyTwogether. Please process the payment to confirm the booking.",
    image: { logo },
    order_id: order_id,
    handler: async function (response) {
      try {
        const data = {
          receipt,
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_order_id: response.razorpay_order_id,
          razorpay_signature: response.razorpay_signature,
          amount: amount.toString(),
        };

        await axios.post(`${BaseURL}/api/client/razorPay/verifyPayment`, data, {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        });
        window.location.href = `https://easytwogether.com/v2/client/meetingPaymentSuccess/${receipt}`;
      } catch (err) {
        console.log(err);
        alert(err.response?.data?.message ?? err.message);
      }
    },
    prefill: {
      name: client.name,
      email: client.email,
      contact: client.phoneNo,
    },
    notes: {
      //   address: "Soumya Dey Corporate Office",
    },
    theme: {
      color: "#143F6B",
    },
  };
  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
}