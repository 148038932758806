import Typography from "@mui/material/Typography";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import { useNavigate } from "react-router-dom";
import { Button, Stack, TextField } from "@mui/material";
import Facebook from "../../../utils/Facebook.png";
import Instagram from "../../../utils/Instagram.png";
import Twitter from "../../../utils/Twitter.png";
import axios from "axios";
import { BaseURL, validateEmail } from "../../../utils/consts";
import { useState } from "react";
export default function LandingFooter() {
  const [email, setEmail] = useState("");

  const navigate = useNavigate();
  const subscribe = async () => {
    try {
      if (!validateEmail(email)) {
        window.alert("Please enter a valid email address");
        return;
      }

      await axios.get(`${BaseURL}/api/landing/subscribe/${email}`);
      window.alert("Thank you for subscribing!");
      setEmail("");
    } catch (err) {
      console.log(err.message);
    }
  };
  return (
    <>
      <Stack
        sx={{ backgroundColor: "#FAF2FF" }}
        paddingX={{ lg: 25, md: 5, sm: 5, xs: 5 }}
        pt={5}
        mt={5}
        alignItems={"center"}
      >
        <Typography
          // fontWeight={550}
          color="black"
          fontSize={{ lg: 30, md: 30, sm: 30, xs: 22 }}
          textAlign={"center"}
          pb={2}
        >
          Subscribe to Our Newsletter & Updates!
        </Typography>
        <Stack
          flexDirection={{ md: "row" }}
          width={{ lg: 570, md: 480 }}
          height={{ lg: "auto", md: "auto", sm: 100, xs: 100 }}
          spacing={{ lg: 1, xs: 50 }}
          // alignItems={"center"}
          sx={{ alignItems: "stretch" }}
          justifyContent={"space-between"}
        >
          <TextField
            id="outlined-basic"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email address"
            variant="outlined"
            sx={{
              width: { lg: 400, xs: 300 },
              height: "20px",
              "& .MuiInputBase-input": {
                height: "20px",
                padding: "10px",
              },
            }}
            autoComplete="off"
          />
          <Button
            onClick={subscribe}
            sx={{
              backgroundColor: "#662d91",
              marginTop: "0 !important",
              // "@media (min-width: 1200px)": {
              //   marginTop: -1,
              // },
              textTransform: "none",
              width: { md: 150, xs: 300 },
            }}
            variant="contained"
          >
            Subscribe
          </Button>
        </Stack>
      </Stack>
      <Stack
        sx={{ backgroundColor: "#FAF2FF" }}
        pt={5}
        paddingX={{ lg: 5, md: 5, sm: 5, xs: 0 }}
      >
        <Stack
          flexDirection={{ lg: "row", xs: "column" }}
          alignItems={{ lg: "end", xs: "center" }}
          justifyContent={"space-between"}
          spacing={5}
          paddingBottom={{ lg: 5, md: 5, sm: 5, xs: 5 }}
          paddingX={{ lg: 5, md: 5, sm: 5, xs: 5 }}
        >
          <Stack spacing={1}>
            <Typography
              fontWeight={700}
              color="#42445D"
              fontSize={20}
              paddingLeft={"30px"}
              // textAlign={{ xs: "center" }}
            >
              Virtue Mind Pvt. Ltd
            </Typography>
            <a
              href="mailto:support@peacetwogether.com"
              style={{ textDecoration: "none" }}
            >
              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <EmailIcon sx={{ color: "gray" }} />
                <Typography color="#42445D" variant="body2">
                  support@peacetwogether.com
                </Typography>
              </Stack>
            </a>
            <a href="tel:+916291075366" style={{ textDecoration: "none" }}>
              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <CallIcon sx={{ color: "gray" }} />
                <Typography color="#42445D" variant="body2">
                  +91 6291075366
                </Typography>
              </Stack>
            </a>

            <Stack direction={"row"} spacing={1} alignItems={"start"}>
              <LocationOnIcon sx={{ color: "gray" }} />
              <Typography color="#42445D" width={300} variant="body2">
                23/A/V/S D.H. Road, Flat-A, 4th Floor, Kolkata, West Bengal,
                India, 700053{" "}
              </Typography>
            </Stack>
          </Stack>
          <Stack alignItems={{ lg: "end", xs: "space-between" }} spacing={2}>
            <Stack
              direction={"row"}
              justifyContent={{ xs: "center" }}
              spacing={3}
              paddingRight={1}
            >
              <img
                src={Facebook}
                alt=""
                style={{ cursor: "pointer" }}
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/profile.php?id=100091928088994",
                    "_blank"
                  )
                }
              />
              <img
                src={Instagram}
                alt=""
                style={{ cursor: "pointer" }}
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/easytwogether/?igshid=YmMyMTA2M2Y%3D",
                    "_blank"
                  )
                }
              />
              <img src={Twitter} alt="" />
            </Stack>
            <Stack
              flexDirection={"row"}
              flexWrap={"wrap"}
              justifyContent={{ xs: "center" }}
              spacing={0}
            >
              <Button
                onClick={() => navigate("/career")}
                variant="text"
                sx={{ textTransform: "none", color: "gray" }}
              >
                Careers
              </Button>
              <Button
                onClick={() => navigate("/faqs")}
                variant="text"
                sx={{ textTransform: "none", color: "gray", width: "100px" }}
              >
                FAQs
              </Button>
              <Button
                onClick={() => navigate("/policies")}
                variant="text"
                sx={{ textTransform: "none", color: "gray", width: "170px" }}
              >
                Terms & Conditions{" "}
              </Button>

              <Button
                onClick={() => navigate("/consultant/login")}
                variant="text"
                sx={{ textTransform: "none", color: "gray" }}
              >
                Professionals{" "}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
