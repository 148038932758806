import { useState, useCallback } from "react";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Logo from "../logo";
import box from "../../utils/box.png";
import { Avatar, IconButton, Paper, Stack, useMediaQuery } from "@mui/material";

import HomeIcon from "../../utils/sidepanel/Home.png";
import ServicesIcon from "../../utils/sidepanel/Activity.png";
import AppointmentsIcon from "../../utils/sidepanel/Appointments.png";
import HistoryIcon from "../../utils/sidepanel/History.png";
import HelpIcon from "../../utils/sidepanel/Help.png";
import SettingsIcon from "../../utils/sidepanel/Settings.png";
import SearchBar from "./searchBar";
import { useNavigate } from "react-router-dom";
import { drawerWidth2, safeJSONParse } from "../../utils/consts";
import QuizIcon from "@mui/icons-material/Quiz";
import ForumIcon from "@mui/icons-material/Forum";
import NotificationButton from "./notificationButton";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import CategoryIcon from "@mui/icons-material/Category";
import InventoryIcon from "@mui/icons-material/Inventory";
import PolicyIcon from "@mui/icons-material/Policy";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
export default function Panel(props) {
  const [mobileOpen, setMobileOpen] = useState(window.innerWidth > 1000);
  const [selectedTab, setSelectedTab] = useState("Home");
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width:900px)");

  const tabSelect = ({ link, anchor }) => {
    if (link) {
      navigate(link);
      setMobileOpen(false);
      return;
    }
    if (anchor === "logout") {
      localStorage.setItem("token", "");
      localStorage.setItem("company", "");
      localStorage.setItem("username", "");
      localStorage.setItem("pic", "");
      navigate("/");
      return;
    }
    setSelectedTab(anchor);
    if (props.isHome && window.innerWidth > 1000) {
      document.getElementById(anchor).scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
      return;
    }
    navigate(`/v2/client/home`);
    setMobileOpen(false);
    setTimeout(() => {
      document.getElementById(anchor)?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }, 1000);
  };

  function TabItem(index, tab) {
    return (
      <ListItem key={index} disablePadding onClick={() => tabSelect(tab)}>
        <ListItemButton>
          <ListItemIcon>
            <IconButton>
              {tab.image ? (
                <Paper sx={{ padding: 0.7, display: "flex" }}>
                  <img
                    color="blue"
                    height={"15px"}
                    src={tab.image}
                    alt="Icon"
                  />
                </Paper>
              ) : (
                <Paper sx={{ padding: 0.4, display: "flex" }}>
                  {tab.icon}{" "}
                </Paper>
              )}
            </IconButton>{" "}
          </ListItemIcon>
          <ListItemText primary={tab.label} />
        </ListItemButton>
      </ListItem>
    );
  }

  return (
    <>
      {isSmallScreen ? (
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "white",
          }}
        >
          <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
            <Logo />
            <IconButton
              sx={{ margin: 0 }}
              onClick={() => setMobileOpen(!mobileOpen)}
            >
              <img
                src={box}
                alt=""
                style={{ cursor: "pointer" }}
                width={"30px"}
              />
            </IconButton>
          </Toolbar>
        </AppBar>
      ) : (
        <AppBar
          position="fixed"
          sx={{
            //zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "white",
            // elevation: 0,
            boxShadow: "none",
            "&.MuiAppBar-root": { boxShadow: "none" },
          }}
        >
          <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
            <Logo />
            <Stack
              direction={"row"}
              spacing={1}
              justifyContent={"center"}
              sx={{
                flexGrow: 1,
                paddingX: 5,
              }}
            >
              <SearchBar />
              <NotificationButton />
            </Stack>
            <Stack color={"#143F6B"} paddingX={2}>
              <Typography fontWeight={700} variant="body1">
                {localStorage.getItem("username")}
              </Typography>
              <Typography fontWeight={500} fontSize={10}>
                {localStorage.getItem("company") ? "Corporate " : "Personal "}
                Profile
              </Typography>
            </Stack>
            <Avatar
              alt={localStorage.getItem("username")}
              sx={{ width: 46, height: 46 }}
              src={safeJSONParse(localStorage.getItem("pic"))?.profilePic}
            />{" "}
          </Toolbar>
        </AppBar>
      )}
      <Drawer
        open={mobileOpen}
        variant={isSmallScreen ? "temporary" : "permanent"}
        onClose={useCallback(() => {
          setMobileOpen(false);
        }, [])}
        sx={{
          width: drawerWidth2,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            height: {
              xs: "calc(100% - 63.63px)",
              md: "calc(100% - 83px)",
            }, // Adjust the height
            marginTop: { md: 13, xs: 8 }, // Shifting the visual part of the drawer down
            borderTopRightRadius: 20,
            paddingTop: 0,
            width: drawerWidth2,
            boxSizing: "border-box",
            backgroundColor: "#143F6B",
            color: "#fff",
            overflow: "auto",
            "&::-webkit-scrollbar": {
              width: "5px",
            },
            "& *": {
              scrollbarWidth: "thin",
            },
          },
        }}
      >
        <Stack height={"100%"} justifyContent={"space-between"}>
          <Stack>
            <List>
              {[
                { label: "Home", image: HomeIcon, anchor: "home" },
                {
                  label: "Services",
                  icon: (
                    <CategoryIcon
                      sx={{
                        height: "20px",
                        width: "20px",
                        color: "#143F6B",
                      }}
                    />
                  ),
                  anchor: "services",
                },
                {
                  label: "Appointments",
                  icon: (
                    <SupervisorAccountIcon
                      sx={{ height: "20px", width: "20px", color: "#143F6B" }}
                    />
                  ),
                  anchor: "appointments",
                },
                // {
                //   label: "Booking History",
                //   image: HistoryIcon,
                //   anchor: "history",
                // },
                {
                  label: "Subscriptions",
                  icon: (
                    <InventoryIcon
                      sx={{ height: "20px", width: "20px", color: "#143F6B" }}
                    />
                  ),
                  link: "/v2/client/subscriptions",
                },
              ].map((tab, index) => TabItem(index, tab))}
            </List>
            <Typography paddingX={2} fontWeight={700} variant="body2">
              Activities
            </Typography>{" "}
            <List>
              {[
                {
                  label: "Easy Buddy",
                  icon: (
                    <ForumIcon
                      sx={{ height: "20px", width: "20px", color: "#143F6B" }}
                    />
                  ),
                  link: "/v2/client/easyBuddy",
                },
                {
                  label: "Mood Journals",
                  image: ServicesIcon,
                  link: "/v2/client/moodJournal",
                },
                {
                  label: "CBT Quiz",
                  icon: (
                    <QuizIcon
                      sx={{ height: "20px", width: "20px", color: "#143F6B" }}
                    />
                  ),
                  link: "/v2/client/cbtQuiz",
                },
                {
                  label: "Stress Tracker",
                  icon: (
                    <DynamicFormIcon
                      sx={{ height: "20px", width: "20px", color: "#143F6B" }}
                    />
                  ),
                  link: "/v2/client/stressTracker",
                },
                {
                  label: "Depression Tracker",
                  icon: (
                    <EqualizerIcon
                      sx={{ height: "20px", width: "20px", color: "#143F6B" }}
                    />
                  ),
                  link: "/v2/client/depressionTracker",
                },
                {
                  label: "Legal Buddy",
                  icon: (
                    <PolicyIcon
                      sx={{ height: "20px", width: "20px", color: "#143F6B" }}
                    />
                  ),
                  link: "/v2/client/legalBuddy",
                },
              ].map((tab, index) => TabItem(index, tab))}
              {localStorage.getItem("company") &&
                TabItem(3, {
                  label: "Company Feedback",
                  image: AppointmentsIcon,
                  link: "/v2/client/feedback",
                })}
            </List>
            <Typography paddingX={2} fontWeight={700} variant="body2">
              Support
            </Typography>
            <List>
              {[
                {
                  label: "Help Center",
                  image: HelpIcon,
                  link: "/v2/client/helpCenter",
                },
                {
                  label: "Settings",
                  image: SettingsIcon,
                  link: "/v2/client/settings",
                },
                {
                  label: "Log Out",
                  icon: (
                    <PowerSettingsNewIcon
                      sx={{ height: "20px", width: "20px", color: "#143F6B" }}
                    />
                  ),
                  anchor: "logout",
                },
              ].map((tab, index) => TabItem(index, tab))}
            </List>
          </Stack>
          <Stack
            paddingBottom={5}
            paddingTop={5}
            width={"100%"}
            alignItems={"center"}
          >
            <Typography textAlign={"center"} fontSize={10}>
              {" "}
              ©Copyright 2024 | Virtue Mind Pvt. Ltd
            </Typography>
            <Typography textAlign={"center"} fontSize={10}>
              {" "}
              Powered by BIRD AI RESEARCH
            </Typography>
          </Stack>
        </Stack>
      </Drawer>
    </>
  );
}
