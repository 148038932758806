import { Box, Typography } from "@mui/material";
import { helpLine } from "../../utils/consts";

export default function NewFooter(props) {
  if (props.isLegal)
    return (
      <Box
        sx={{ padding: "6vh", backgroundColor: "#143F6B", color: "whiteSmoke" }}
      >
        <Typography
          fontSize={{
            lg: 18,
            md: 18,
            sm: 16,
            xs: 16,
          }}
          align={"left"}
        >
          <b>Disclaimer:</b> The rules of the Bar Council of India prohibit law
          firms from soliciting work or advertising in any manner. The user
          acknowledges that: The user wishes to gain more information about
          peacetwogether.com, its practice areas and its attorneys, for his/her
          own information and use. The information is made available/provided to
          the user only on his/her specific request and any information obtained
          or material downloaded from this website is completely at the user’s
          volition and any transmission, receipt or use of this site is not
          intended to, and will not, create any lawyer-client relationship. None
          of the information contained on the website is in the nature of a
          legal opinion or otherwise amounts to any legal advice.
          peacetwogether.com is not liable for any consequence of any action
          taken by the user relying on material/information provided under this
          website. In cases where the user has any legal issues, he/she in all
          cases must seek independent legal advice. peacetwogether.com is not
          encouraging divorce & is only helping people in getting legal &
          correct advice from experienced team of lawyers / counsellors. We use
          cookies, just to track visits to our website, we store no personal
          details. The platform shall strictly adhere to the guidelines,
          policies, and procedures set forth by PeaceTwogether in the provision
          of legal guidance and counseling services. Failure to comply with
          these guidelines may result in immediate termination of service and
          other legal consequences as deemed appropriate under the law.
          PeaceTwogether expressly disclaims any responsibility or liability for
          the filing, prosecution, or resolution of any cases, disputes, or
          legal matters between parties who utilize the platform's services.
          Users are solely responsible for their own legal affairs and should
          seek independent legal advice for any actions taken or not taken as a
          result of using this platform.
        </Typography>
      </Box>
    );
  else
    return (
      <Box sx={{ padding: 5, backgroundColor: "#143F6B", color: "whiteSmoke" }}>
        <Typography
          fontSize={{
            lg: 22,
            md: 22,
            sm: 20,
            xs: 20,
          }}
          align={"center"}
        >
          Will you like to have an emotional support call with our EasyBuddy to
          talk your heart out? Don’t worry, your call will be completely
          anonymous. It’s free.
          <span style={{ color: "orange" }}>
            Dial{" "}
            <a style={{ color: "orange" }} href={`tel:${helpLine}`}>
              {helpLine}
            </a>
          </span>
        </Typography>
      </Box>
    );
}
