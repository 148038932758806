import { useNavigate } from "react-router-dom";
import PeaceTwogetherImg from "../utils/peace_logo.png";
import { Box } from "@mui/material";

export default function Logo({ width }) {
  const navigate = useNavigate();
  return (
    <Box
      component={"img"}
      src={PeaceTwogetherImg}
      sx={{
        cursor: "pointer",
        paddingY: {md:4},
        width: width || { lg: "220px", md: "200px", sm: "200px", xs: "200px" },
      }}
      onClick={() => navigate("/")}
      alt="PeaceTwogether Logo"
    />
  );
}
