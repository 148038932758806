import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, Hidden, IconButton, Stack, Tooltip } from "@mui/material";
import Scenery from "../../utils/scenery.png";
import Banner from "../../utils/banner.jpg";
import CircleIcon from "@mui/icons-material/Circle";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router-dom";
import { useState } from "react";


function Carousel(props) {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] =useState(0);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
  };

  const handleBack = () => {
    if (activeStep === 0) {
      setActiveStep(maxSteps - 1);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };
  const slides = props.isConsultant
    ? [
        <Box
          id="home"
          sx={{
            backgroundColor: "#FAFAFA",
            paddingTop: 2,
            borderRadius: 5,
            height: { xs: 330, md: 265 },
            width: "100%", // take up full width of its container
            minWidth: "280px",
          }}
        >
          <Stack height={"100%"}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              height={"100%"}
              justifyContent={"space-between"}
              spacing={5}
              sx={{ paddingX: { xs: 3, md: 4 } }}
            >
              <Stack>
                <Typography
                  fontWeight={700}
                  fontSize={{ xs: "24px", lg: "28px" }}
                  color={"#143F6B"}
                >
                  Hello, {localStorage.getItem("consultantName")} !
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  pb={2}
                  color={"#143F6B"}
                >
                  Welcome home to Peacetwogether! <br />
                  Dive deep with integrity, amplify positive impact, connect
                  genuinely, and evolve through feedback.
                </Typography>
                <Typography
                  paddingTop={2}
                  variant="h5"
                  fontWeight={550}
                  color={"#143F6B"}
                >
                  {props.totalMeetings + " "}
                  {/* <span
                    style={{
                      fontSize: "20px",
                      color: "rgba(20, 63, 107, 0.63)",
                      fontWeight: 700,
                      marginLeft: 5,
                    }}
                  > */}
                  Sessions Booked
                  {/* </span> */}
                </Typography>
                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                  {!props.adminVerified ? (
                    <>
                      {" "}
                      <div
                        style={{
                          backgroundColor: "#A92020",
                          width: 10,
                          height: 10,
                          borderRadius: "50%",
                        }}
                      />
                      <Typography color={"#A92020"}>
                        <b>Account Status:</b> Not Verified
                      </Typography>{" "}
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          backgroundColor: "darkgreen",
                          width: "10px",
                          height: "10px",
                          borderRadius: "50%",
                        }}
                      />
                      <Typography
                        color={"#444444"}
                        maxWidth={{ sm: "auto", xs: "70vw" }}
                      >
                        <b>Account Status:</b> Verified and Live
                      </Typography>
                    </>
                  )}
                </Stack>
              </Stack>
              <Hidden implementation="css" xlDown>
                <img src={Scenery} alt="Scenery" />
              </Hidden>
            </Stack>
            <Stack
              sx={{
                paddingBottom: 0,
                justifyContent: "center",
                flexDirection: "row",
              }}
            >
              <IconButton sx={{ color: "purple" }}>
                <CircleIcon sx={{ height: 10, width: 10 }} />
              </IconButton>
              <IconButton sx={{ color: "grey" }} onClick={handleNext}>
                <CircleIcon sx={{ height: 10, width: 10 }} />
              </IconButton>
            </Stack>
          </Stack>
        </Box>,
        <Box
          sx={{
            width: "100%",
            height: { xs: 330, md: 265 },
            overflow: "hidden",
            position: "relative",
            objectFit: "contain",
            backgroundImage: { md: `url(${Banner})` },
            //backgroundImage: `url(${"https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60"})`,
            backgroundSize: "cover", // Adjusted from "cover" to "contain"
            backgroundPosition: "center", // Adjusted to center the background image
            borderRadius: 5,
            border: "1px solid gray",
          }}
        >
          {" "}
          <Stack
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              paddingX: 5,
              paddingTop: 5,
              width: "100%",
            }}
          >
            <Typography
              textAlign={{ md: "end" }}
              variant="h4"
              sx={{
                // color: "white",
                fontWeight: 700,
                // fontSize: { lg: "30px", md: "24px", xs: "26px" },
              }}
            >
              Empowering<span style={{ color: "#143F6B" }}> Minds</span>, one
              Journey at a Time <br />
            </Typography>
            <Typography
              textAlign={{ md: "end" }}
              variant="h7"
              sx={{
                // color: "white",
                // fontSize: { lg: "24px", md: "18px", xs: "20px" },
                fontWeight: 700,
              }}
            >
              {" "}
              Every emotion matters, Let's make it Easy Together
              <br />
              Deliver Trusted Guidance and Assured Secrecy.{" "}
            </Typography>
            <Stack
              sx={{
                padding: 0,
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <IconButton sx={{ color: "grey" }} onClick={handleBack}>
                <CircleIcon sx={{ height: 10, width: 10 }} />
              </IconButton>
              <IconButton sx={{ color: "blue" }}>
                <CircleIcon sx={{ height: 10, width: 10 }} />
              </IconButton>
            </Stack>
          </Stack>
        </Box>,
      ]
    : [
        <Box
          id="home"
          sx={{
            backgroundColor: "#FAFAFA",
            borderRadius: 5,
            height: { md: 285, xs: 330 },
            width: "100%", // take up full width of its container
            minWidth: "280px",
          }}
        >
          <Stack height={"100%"}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              height={"100%"}
              justifyContent={"space-between"}
              spacing={5}
              sx={{ paddingX: { xs: 3, md: 4 }, paddingY: 3 }}
            >
              <Stack>
                <Typography
                  fontWeight={700}
                  fontSize={{ lg: "28px", md: "24px", xs: "22px" }}
                  color={"#143F6B"}
                >
                  Hello, {localStorage.getItem("username")} !
                </Typography>
                <Typography variant="" color={"#143F6B"}>
                  Welcome home to PeaceTwogether! <br />
                  Remember, this is a safe space for you to share, reflect, and
                  grow.{" "}
                </Typography>
                <Stack direction="row" alignItems="center">
                  <Button
                    variant="text"
                    sx={{
                      justifyContent: "start",
                      textTransform: "none",
                      ":hover": {
                        backgroundColor: "transparent",
                        textDecoration: "underline",
                        color: "#143F6B",
                      },
                    }}
                    onClick={() => navigate("/v2/client/mws")}
                  >
                    <Typography
                      paddingY={2}
                      variant="h6"
                      fontWeight={550}
                      color={"#143F6B"}
                      sx={{
                        textTransform: "none",
                      }}
                    >
                      {(props.mws || 0).toFixed(2) || 0}
                      <span
                        style={{
                          fontSize: "20px",
                          color: "rgba(20, 63, 107, 0.63)",
                          fontWeight: 300,
                        }}
                      >
                        <b> /100 MWS, </b>{" "}
                        {new Date().toLocaleString("default", {
                          month: "long",
                        })}
                        {"" + (new Date().getFullYear() % 100)}
                      </span>
                    </Typography>
                  </Button>
                  <Tooltip title="What does MWS score mean?">
                    <IconButton
                      sx={{
                        color: "rgba(20, 63, 107, 0.43)",
                        ml: 0,
                        mt: 1,
                        p: 0,
                        alignSelf: "flex-start",
                      }}
                      onClick={() => navigate("/v2/client/mws")}
                    >
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Stack>
                {/* <Stack
                  direction={"row"}
                  alignItems={{ sm: "center", xs: "start" }}
                  spacing={1}
                > */}
                {/* <div
                        style={{
                          backgroundColor: "#A92020",
                          width: 10,
                          height: 10,
                          borderRadius: "50%",
                        }}
                      /> */}
                {/* <Typography color={"#A92020"}>
                        Need Intervention
                      </Typography> */}

                <Typography
                  color={"#444444"}
                  maxWidth={{ sm: "auto", xs: "70vw" }}
                >
                  <span
                    style={{
                      backgroundColor: "darkgreen",
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      display: "inline-block",
                      marginRight: "4px",
                    }}
                  />
                  Prioritize Self Care. You deserve Love, Kindness, and Respect
                </Typography>
              </Stack>
              <Hidden implementation="css" xlDown>
                <img src={Scenery} alt="Scenery" />
              </Hidden>
            </Stack>
            <Stack
              sx={{
                paddingBottom: 1,
                justifyContent: "center",
                flexDirection: "row",
              }}
            >
              <IconButton sx={{ color: "purple" }}>
                <CircleIcon sx={{ height: 10, width: 10 }} />
              </IconButton>
              <IconButton sx={{ color: "grey" }} onClick={handleNext}>
                <CircleIcon sx={{ height: 10, width: 10 }} />
              </IconButton>
            </Stack>
          </Stack>
        </Box>,
        <Box
          sx={{
            width: "100%",
            height: { md: 285, xs: 330 },
            overflow: "hidden",
            position: "relative",
            backgroundImage: `url(${"https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60"})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: 5,
          }}
        >
          <Stack
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              paddingX: 5,
              paddingTop: 5,
              width: "100%",
            }}
          >
            <Typography
              sx={{
                color: "white",
                fontWeight: 700,
                fontSize: { lg: "30px", md: "24px", xs: "26px" },
              }}
            >
              Your Journey To Mental Wellness ...
              <br />
            </Typography>
            <Typography
              sx={{
                color: "white",
                fontSize: { lg: "24px", md: "18px", xs: "20px" },
                fontWeight: 700,
              }}
            >
              {" "}
              Every emotion matters, Let's make it Easy Together
              <br />
              Expert Care, 100% Anonymity
            </Typography>
            <Stack
              sx={{
                padding: 1,
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <IconButton sx={{ color: "white" }} onClick={handleBack}>
                <CircleIcon sx={{ height: 10, width: 10 }} />
              </IconButton>
              <IconButton sx={{ color: "pink" }}>
                <CircleIcon sx={{ height: 10, width: 10 }} />
              </IconButton>
            </Stack>
          </Stack>
        </Box>,
      ];

  const maxSteps = slides.length;

  return (
   <>
   {slides[activeStep]}
   </>
  );
}

export default Carousel;
