import { Stack, Typography } from "@mui/material";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
  { name: "Consultations", Count: 0 },
  { name: "Stress Reports", Count: 1 },
  { name: "Depression Reports", Count: 2 },
  { name: "CBT Quizzes", Count: 1 },
  { name: "Journal Entries", Count: 30 },
  { name: "AI Chat Sessions", Count: 2 },
];

export default function HorizontalBarGraph(props) {
  return (
    <Stack
      sx={{
        width: "100%",
        height: "343px",
        backgroundColor: "white",
        borderRadius: "10px",
        border: "1px solid lightgray",
        p: 2,
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 15px;",
      }}
    >
      <Typography
        variant=""
        width={"100%"}
        textAlign={"center"}
        pb={2}
        sx={{ marginLeft: "20px", width: "100%" }}
      >
        <b>Category Wise Participation</b> (Activity Analysis)
      </Typography>
      <ResponsiveContainer width="100%" height="80%">
        <BarChart
          layout="vertical"
          width={500}
          height={300}
          data={props.data}
          margin={{
            top: 10,
            right: 0,
            left: 20,
            bottom: 0,
          }}
          barSize={15} // Adjust the barSize value to make the bars thinner
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" tick={{ fontSize: "10px" }} />
          <YAxis
            dataKey="name"
            type="category"
            tick={{
              fontWeight: "bold", // Make the Y-axis labels bold
              fontSize: "10px", // Adjust the font size of the Y-axis labels
            }}
          />
          <Tooltip />
          <Legend />
          <Bar
            dataKey="Count"
            fill="#20B2AA"
            activeBar={<Rectangle fill="pink" stroke="salmon" />}
          />
        </BarChart>
      </ResponsiveContainer>
      <Typography sx={{ }} textAlign={"center"}>
        The number of test, journal entries and consultation taken by the user
      </Typography>
    </Stack>
  );
}
