import { useState } from "react";
import { AudioRecorder } from "react-audio-voice-recorder";
import axios from "axios";
import { DialogContent } from "@mui/material";
function AudioCapture(props) {
  const [isRecording, setIsRecording] = useState(false);

  const transcribeAudio = async (audioBlob) => {
    props.setIsRecording(false);
    const formData = new FormData();
    formData.append("file", audioBlob, "openai.wav");
    formData.append("model", "whisper-1");
    formData.append("prompt", "EasyTwogether");
    try {
      const response = await axios.post(
        "https://api.openai.com/v1/audio/transcriptions",
        formData,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_KEY}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      props.handleSubmit(response.data.text, true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
        <AudioRecorder
          onRecordingComplete={transcribeAudio}
          record={isRecording}
          showUI={false}
          mimeType="audio/mp3"
          style={{ width: "100%", height: "100px" }}
          audioTrackConstraints={{
            noiseSuppression: true,
            echoCancellation: true,
          }}
        />
      </DialogContent>
    </>
  );
}

export default AudioCapture;
