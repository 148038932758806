import Image from "../../utils/profile.jpg";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Rating from "@mui/material/Rating";
import { useNavigate } from "react-router-dom";

export default function LiveConsultantCard(props) {
  const navigate = useNavigate();
  if (!props.consultant) return null;
  return (
    <Paper
      onClick={() =>
        navigate(localStorage.getItem("token") ? `/v2/client/consultantProfile/${props.consultant._id}` : "/login")
      }
      sx={{
        borderRadius: 5,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "0px",
        cursor: "pointer",
        width: 240,
        transition: "0.3s",
        "&:hover": {
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          transform: "scale(1.1)",
        },
      }}
    >
      <img
        className=""
        style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }}
        width={240}
        height={250}
        src={props.consultant.photoLink || Image}
        alt=""
      />
      <Typography
        textAlign={"center"}
        variant="h6"
        style={{ fontWeight: "bold" }}
      >
        {props.consultant.name}
      </Typography>
      <Typography variant="h7" gutterBottom textAlign={"center"} style={{}}>
        {props.consultant.specialisation}
      </Typography>
      {props.consultant.averageRating > 0 && (
        <Rating
          name="read-only"
          precision={0.5}
          value={props.consultant.averageRating || 0}
          readOnly
        />
      )}
    </Paper>
  );
}
