import { useNavigate } from "react-router-dom";
import EasyButton2 from "../easyButton2";
import Table from "../table";
import { Badge, Box, Tab, Tabs } from "@mui/material";
import { useState } from "react";
const options = [
  {
    title: "All",
    filter: (data) => data,
  },
  {
    title: "Verified",
    filter: (data) => data.adminVerified,
  },
  {
    title: "Unverified",
    filter: (data) => !data.adminVerified && !data.blocked,
  },
  {
    title: "Blocked",
    filter: (data) => data.blocked,
  },
];
export default function ConsultantRecords(props) {
  const [selected, setSelected] = useState(0);
  const navigate = useNavigate();
  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <Tabs
        value={selected}
        onChange={(event, newValue) => {
          setSelected(newValue);
        }}
        centered
        variant="fullWidth"
        indicatorColor="primary"
        sx={{ backgroundColor: "white", boxShadow: "none" }}
      >
        {options?.map((tab, index) => (
          <Tab
            key={index + 1}
            sx={{
              textTransform: "none",
              fontSize: "14px",
              fontWeight: "bold",
              minWidth: index === 2 ? "120px" : "",
            }}
            label={
              <Badge
                badgeContent={index === 2 ? props.unverifiedConsultants : 0}
                color="error"
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 5,
                  }}
                >
                  {tab?.title}
                </div>
              </Badge>
            }
          />
        ))}
      </Tabs>
      <Table
        title={"Consultants"}
        data={props.data?.filter(options[selected]?.filter)}
        columns={[
          { header: "Name", accessorKey: "name" },
          { header: "Email", accessorKey: "email" },
          { header: "Phone No.", accessorKey: "phoneNo" },
          {
            header: "Specialisation",
            accessorKey: "specialisation",
          },
          { header: "AvgRating", accessorKey: "averageRating" },
          { header: "NoOfRatings", accessorKey: "noOfRatings" },
          {
            header: "AdminVerified",
            accessorKey: "adminVerified",
            Cell: ({ cell, column }) => (
              <p
                style={{
                  backgroundColor: cell.row.original.blocked
                    ? "rosybrown"
                    : cell.row.original.adminVerified
                    ? "darkgreen"
                    : "darkred",
                  color: "white",
                  padding: "5px",
                  textAlign: "center",
                  borderRadius: "5px",
                  width: "80px",
                }}
              >
                {cell.row.original.blocked
                  ? "Blocked"
                  : cell.row.original.adminVerified
                  ? "Yes"
                  : "No"}{" "}
              </p>
            ),
          },
          {
            header: "More",
            accessorKey: "more",
            Cell: ({ cell, column }) => (
              <EasyButton2
                label="More Details"
                size="smallest"
                onClick={() =>
                  navigate(`/viewPoint/specialist/${cell.row.original._id}`)
                }
              />
            ),
          },
        ]}
      />
    </Box>
  );
}