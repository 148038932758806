import {
  Badge,
  Box,
  Card,
  CardContent,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Table from "../../components/v2/table";
import { useEffect } from "react";
import { BaseURL } from "../../utils/consts";
import axios from "axios";
import { useState } from "react";
import moment from "moment";
import EasyButton2 from "../../components/v2/easyButton2";
import ConsultantPieChart from "../../components/v2/admin/consultantsPie";
import RevenueLineChart from "../../components/v2/admin/revenueLineChart";
import CompanyRecords from "../../components/v2/admin/companyRecords";
import ConsultantRecords from "../../components/v2/admin/consultantRecords";
import BarGraph from "../../components/v2/barChart";
import Logo from "../../components/logo";
import RescheduleMeeting from "../../components/v2/admin/rescheduleMeeting";

export default function AdminDashboard() {
  const [data, setData] = useState({});
  const [meetings, setMeetings] = useState({
    data: [],
    index: 0,
    title: "Today's Meetings",
  });
  const [rescheduleMeeting, setRescheduleMeeting] = useState({
    open: false,
    id: "",
  });
  const options = [
    { title: "Today's Meetings", param: "today" },
    { title: "Upcoming Meetings", param: "upcoming" },
    { title: "History", param: "history" },
  ];
  const meetingColumns = [
    {
      header: "Date",
      accessorKey: "date",
      Cell: ({ cell }) => (
        <p>{moment(cell.row.original.date).format("DD-MM-YYYY")}</p>
      ),
    },
    {
      header: "Time",
      accessor: "slot",
      Cell: ({ cell }) => (
        <p>
          {cell.row.original.slot.split(" ")[0] +
            ", " +
            cell.row.original.pack.duration}
        </p>
      ),
    },
    {
      header: "Consultant",
      accessor: "consultant",
      Cell: ({ cell }) => <p>{cell.row.original.specialist.name}</p>,
    },
    {
      header: "Consultant Phone No",
      accessor: "client",
      Cell: ({ cell }) => <p>{cell.row.original.specialist.phoneNo}</p>,
    },
    {
      header: "Client",
      accessorKey: "client",
      Cell: ({ cell }) => <p>{cell.row.original.client.name}</p>,
    },
    {
      header: "Client Phone No",
      accessorKey: "clientPhone",
      Cell: ({ cell }) => <p>{cell.row.original.client.phoneNo}</p>,
    },
    {
      header: "Action",
      accessorKey: "action",
      Cell: ({ cell }) => (
        <EasyButton2
          label="Reschedule"
          onClick={() =>
            setRescheduleMeeting({
              open: true,
              id: cell.row.original._id,
              date: cell.row.original.date,
              consultant: "",
              slot: "",
            })
          }
        />
      ),
    },
  ];
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${BaseURL}/api/viewPoint/adminDashboard`,
        {
          headers: {
            "x-auth-token":
              localStorage.getItem("adminToken") ||
              localStorage.getItem("operatorToken"),
          },
        }
      );
      setData(response.data);
      setMeetings({ ...meetings, data: response.data.today });
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <Stack
        direction={{ md: "row", xs: "column-reverse" }}
        alignItems={"center"}
        paddingX={5}
        justifyContent={"space-between"}
      >
        <h1>Admin Dashboard</h1>
        <Logo />{" "}
      </Stack>
      <Stack
        padding={{ md: 5 }}
        spacing={5}
        backgroundColor={`rgba(0, 0, 0, 0.05)`}
      >
        <Stack direction={{ md: "row" }} gap={2} spacing={5}>
          <RevenueLineChart data={data.siteTraffic} />
          <ConsultantPieChart
            data={data.professions}
            consultants={data.metrics?.verifiedConsultants}
            title="Consultants"
          />
        </Stack>
        <Stack direction={{ md: "row" }} gap={2} spacing={5}>
          <BarGraph
            title={"Number of Signups"}
            data={data.signupGraph}
            maxSignup={data.maxSignup}
          />
          <RevenueLineChart
            title="Number of Meetings"
            data={data.meetingGraph}
            stroke={"#4CAF50"}
            legendLabel="count"
            dataKey="count"
          />
        </Stack>
        <Stack
          direction={{ xs: "row" }}
          flexWrap={"wrap"}
          paddingY={2}
          alignItems={"center"}
          justifyContent={"space-evenly"}
          gap={2}
        >
          {[
            {
              label: "Meetings Booked",
              total: data.metrics?.meetings,
              color: "#4CAF50",
            },
            {
              label: "Clients Registered",
              total: data.metrics?.totalClients,
              color: "#CDDC39",
            },
            {
              label: "Verified Consultants",
              total: data.metrics?.verifiedConsultants,
              color: "#2196F3",
            },
            {
              label: "Unverified Consultants",
              total: data.metrics?.unverifiedConsultants,
              color: "#F44336",
            },
            {
              label: "Verified Corporates",
              total: data.metrics?.verifiedCompanies,
              color: "#00BCD4",
            },
            {
              label: "Unverified Corporates",
              total: data.metrics?.unverifiedCompanies,
              color: "#FF9800",
            },
          ].map((item, index) => (
            <Card key={index} sx={{ minWidth: 160 }}>
              <CardContent>
                {" "}
                <Typography
                  sx={{ fontSize: 12 }}
                  color="text.secondary"
                  gutterBottom
                >
                  {item.label}
                </Typography>
                <Typography textAlign={"center"} variant="h3" color={"black"}>
                  {item.total}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Stack>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <Tabs
            value={meetings.index}
            onChange={(event, newValue) => {
              setMeetings({
                data: data[options[newValue].param],
                index: newValue,
                title: options[newValue].title,
              });
            }}
            centered
            variant="fullWidth"
            indicatorColor="primary"
            sx={{ backgroundColor: "white", boxShadow: "none" }}
          >
            {options?.map((tab, index) => (
              <Tab
                key={index + 1}
                sx={{
                  textTransform: "none",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
                label={
                  <Badge badgeContent={tab?.unverifiedCount} color="error">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: 5,
                      }}
                    >
                      {tab?.title}
                    </div>
                  </Badge>
                }
              />
            ))}
          </Tabs>
          <Table
            columns={meetingColumns}
            data={meetings.data}
            title={meetings.title}
          />
        </Box>
        <ConsultantRecords
          data={data.consultants}
          unverifiedConsultants={data.metrics?.unverifiedConsultants}
        />
        <CompanyRecords
          data={data.companies}
          unverifiedCompanies={data.metrics?.unverifiedCompanies}
        />
        {localStorage.getItem("operatorToken") && (
          <Stack padding={4}>
            <EasyButton2
              label="Log Out"
              onClick={() => {
                localStorage.removeItem("operatorToken");
                window.location.href = "/";
              }}
            />
          </Stack>
        )}
      </Stack>
      <RescheduleMeeting
        rescheduleMeeting={rescheduleMeeting}
        setRescheduleMeeting={setRescheduleMeeting}
      />
    </>
  );
}
