import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { useNavigate } from "react-router-dom";
import { Avatar, Button } from "@mui/material";
import axios from "axios";
import { BaseURL } from "../../../utils/consts";
import { useEffect, cloneElement, useState } from "react";
import Logo from "../../../components/logo";
function ElevationScroll(props) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

export default function LandingHeader() {
  const [profile, setProfile] = useState({});
  const navigate = useNavigate();
  const fetchData = async () => {
    try {
      let profile = await axios.post(`${BaseURL}/api/landing/getProfile`, {
        token: localStorage.getItem("token"),
        consultantToken: localStorage.getItem("consultantToken"),
        corporateToken: localStorage.getItem("corporateToken"),
        adminToken: localStorage.getItem("adminToken"),
      });
      if (
        profile.data.client?.name ||
        profile.data.consultant?.name ||
        profile.data.corporate?.name ||
        profile.data.admin?.isLoggedIn
      )
        setProfile(profile.data);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  function getProfile() {
    if (profile.client)
      return (
        <Avatar
          alt={profile.client.name}
          src={profile.client.photoLink}
          sx={{
            marginLeft: "auto",
            cursor: "pointer",
            height: { md: "64px" },
            width: { md: "64px" },
          }}
          onClick={() => navigate("/v2/client/home")}
        ></Avatar>
      );
    else if (profile.consultant)
      return (
        <Avatar
          alt={profile.consultant.name}
          src={profile.consultant.photoLink}
          onClick={() => navigate("/consultant/dashboard")}
          sx={{
            marginLeft: "auto",
            cursor: "pointer",
            height: { md: "64px" },
            width: { md: "64px" },
          }}
        ></Avatar>
      );
    else if (profile.corporate)
      return (
        <Avatar
          alt={profile.corporate.name}
          src={profile.corporate.logo}
          size="large"
          sx={{
            marginLeft: "auto",
            cursor: "pointer",
            height: { md: "64px" },
            width: { md: "64px" },
          }}
          onClick={() => navigate("/corporate/dashboard")}
        ></Avatar>
      );
    else if (profile.admin)
      return (
        <Avatar
          sx={{
            marginLeft: "auto",
            cursor: "pointer",
            bgcolor: "#662d91",
            height: { md: "64px" },
            width: { md: "64px" },
          }}
          onClick={() => navigate("/viewPoint")}
        >
          A
        </Avatar>
      );
    else
      return (
        <div style={{ display: "flex", marginLeft: "auto", width: "300px" }}>
          <Button
            variant="outlined"
            sx={{
              display: { lg: "block", md: "block", sm: "none", xs: "none" },
              marginLeft: "auto",
              backgroundColor: "#262262",
              color: "white",
              borderColor: "black",
              "&:hover": {
                backgroundColor: "#333", // adjust as needed for hover effect
                borderColor: "black",
              },
            }}
            onClick={() => navigate("/corporate/landing")}
          >
            Corporate Pro
          </Button>
          <Button
            variant="outlined"
            sx={{
              display: { lg: "block", md: "block", sm: "none", xs: "none" },
              marginLeft: "auto",
              backgroundColor: "#662d91",
              color: "white",
              borderColor: "black",
              "&:hover": {
                backgroundColor: "#333", // adjust as needed for hover effect
                borderColor: "black",
              },
            }}
            onClick={() => navigate("/login")}
          >
            Sign In
          </Button>
        </div>
      );
  }
  return (
    <ElevationScroll>
      <AppBar
        sx={{
          bgcolor: "white",
          paddingX: { md: 20 },
          paddingY: { lg: 1 },
        }}
      >
        <Toolbar>
          <Logo />
          {getProfile()}
        </Toolbar>
      </AppBar>
    </ElevationScroll>
  );
}
