import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useState, useEffect } from "react";
import axios from "axios";
import { BaseURL, panelPageMargin } from "../../../utils/consts";
import { useParams } from "react-router-dom";
import { Alert, Stack, Typography } from "@mui/material";
import { Loader } from "../../../components/Loader";
import Panel from "../../../components/v2/panel";
import NewConsultantCard from "../../../components/v2/consultantCard";
import NewFooter from "../../../components/v2/footer";

export default function ConsultantsList() {
  const [loading, setLoading] = useState(true);
  const { isLegal } = useParams();
  const [consultants, setConsultants] = useState([]);
  const [error, setError] = useState("");
  let profession =
    isLegal === "true"
      ? []
      : ["Psychologist", "Psychiatrist", "Peer Support", "Coach"];

  const [value, setValue] = useState(
    isLegal === "true" ? "Legal Buddy" : profession[0]
  );

  const fetchData = async () => {
    try {
      setLoading(true);
      let res = await axios.get(`${BaseURL}/api/client/getSpecialists`, {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      });
      setConsultants(res.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      setError(err.response?.data?.message ?? err.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Stack
      minHeight={"100vh"}
      justifyContent={"space-between"}
      spacing={2}
      sx={{ ...panelPageMargin }}
    >
      <Panel />
      <Stack spacing={4} paddingLeft={"3vw"} paddingRight={"3vw"}>
        {error && <Alert severity="error">{error}</Alert>}
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              variant="scrollable"
              onChange={(e, newValue) => setValue(newValue)}
              aria-label="lab API tabs example"
            >
              {profession.map((e, index) => {
                return <Tab key={index} label={e} value={profession[index]} />;
              })}
              <Tab label="Legal Professionals" value="Legal Buddy" />
            </TabList>
          </Box>
          <TabPanel value={value} index={value} sx={{ minHeight: "100vh" }}>
            {loading ? (
              <Loader />
            ) : consultants.filter((e) => e.profession === value).length ===
              0 ? (
              <Typography
                variant="h5"
                color={"GrayText"}
                paddingY={10}
                textAlign={"center"}
              >
                No {value} currently available
              </Typography>
            ) : (
              <Stack
                direction={{ lg: "row", md: "row", sm: "column", xs: "column" }}
                flexWrap={"wrap"}
                alignContent={"center"}
                alignItems={"center"}
                justifyContent={"start"}
                spacing={0}
                gap={5}
              >
                {consultants
                  .filter((e) => e.profession === value)
                  .map((consultant, index) => {
                    return (
                      <NewConsultantCard key={index} consultant={consultant} />
                    );
                  })}
              </Stack>
            )}
          </TabPanel>
        </TabContext>
      </Stack>
      <NewFooter isLegal={isLegal === "true"} />
    </Stack>
  );
}
