import { Stack, Typography } from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const CustomLegend = ({ ranges }) => {
  return (
    <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
      {ranges.map((item, index) => (
        <div
          key={index}
          style={{ marginRight: 20, display: "flex", alignItems: "center" }}
        >
          <div
            style={{
              width: 10,
              height: 10,
              backgroundColor: item.color,
              marginRight: 5,
            }}
          />
          <span
            style={{ fontSize: 12 }}
          >{`${item.label} (${item.range})`}</span>
        </div>
      ))}
    </div>
  );
};

export default function RevenueLineChart({
  title = "Site Visits per Day",
  dataKey = "Site Traffic",
  data,
  XdataKey = "_id",
  ranges,
  domain = ["auto", "auto"],
  stroke = "#8884d8",
  legendLabel = "Site Traffic",
  description,

}) {
  return (
    <Stack
      sx={{
        width: "100%",
        height: "343px",
        backgroundColor: "white",
        borderRadius: "10px",
        border: "1px solid lightgray",
        p: 2,
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 15px;",
      }}
    >
      {" "}
      <Typography
        variant=""
        width={"100%"}
        textAlign={"center"}
        pb={2}
        sx={{ marginLeft: "20px", width: "100%" }}
      >
        <b> {title} </b>
      </Typography>{" "}
      <ResponsiveContainer width="100%" height="80%">
        <LineChart
          width={500}
          height={250}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: -5,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="1 1" vertical={false} />
          <XAxis
            dataKey={XdataKey}
            axisLine={false}
            tickLine={false}
            tick={{ fontSize: 10, fill: "gray" }}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            domain={domain}
            tick={{ fontSize: 10, fill: "gray" }}
          />
          <Tooltip />
          <Legend
            verticalAlign="top"
            align="right"
            iconType="circle"
            iconSize={10}
            formatter={(value, entry, index) => (
              <span style={{ fontSize: "12px", color: "gray" }}>{value}</span>
            )}
          />
          <Line
            type="monotone"
            dataKey={dataKey}
            stroke={stroke}
            strokeWidth={3}
            dot={false}
            name={legendLabel}
          />
        </LineChart>
      </ResponsiveContainer>
      {ranges && <CustomLegend ranges={ranges} />}
      {description && (
        <Typography
          sx={{ marginLeft: "20px", paddingY: 1 }}
          textAlign={"center"}
        >
          {description}
        </Typography>
      )}
    </Stack>
  );
}
