import Typography from "@mui/material/Typography";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";

import NotificationsIcon from "@mui/icons-material/Notifications";
import { Badge, IconButton, Menu, Stack } from "@mui/material";

import { BaseURL } from "../../utils/consts";
import MailIcon from "@mui/icons-material/Mail";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import QuizIcon from "@mui/icons-material/Quiz";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import moment from "moment";
import { useState, useEffect } from "react";
import axios from "axios";

//function to return icon according to notification type
function getIcon(notificationType) {
  switch (notificationType) {
    case "mail":
      return <MailIcon />;
    case "meeting":
      return <CalendarTodayIcon />;
    case "quiz":
      return <QuizIcon />;
    case "subscription":
      return <Inventory2OutlinedIcon />;
    default:
      return <MailIcon />;
  }
}
export default function NotificationButton() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [unread, setUnread] = useState(false);
  const fetchData = async () => {
    try {
      let res = await axios.get(
        `${BaseURL}/api/client/dashboard/notifications`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      setNotifications(res.data.notifications);
      setUnread(res.data.unread);
    } catch (err) {
      console.log(err.message);
    }
  };
  const markAllAsRead = async () => {
    try {
      await axios.put(
        `${BaseURL}/api/client/dashboard/notificationsMarkAllRead`,
        {},
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      setUnread(0);
    } catch (err) {
      console.log(err.message);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  function NotificationItem({ message, date, type, read }) {
    return (
      <MenuItem
        sx={{
          width: "340px",
          whiteSpace: "normal",
          backgroundColor: read ? "inherit" : "#f5f5f5", // Change background if unread
        }}
        onClick={() => setAnchorEl(null)}
      >
        <ListItemIcon>
          {" "}
          {read ? (
            getIcon(type)
          ) : (
            <Badge color="primary" variant="dot">
              {getIcon(type)}
            </Badge>
          )}
        </ListItemIcon>
        <Stack>
          <Typography
            variant="body2"
            sx={{ fontWeight: read ? "normal" : "bold" }}
          >
            {message}
          </Typography>
          <Typography
            variant="caption"
            display="block"
            gutterBottom
            color={"text.secondary"}
          >
            {moment(date).format("MMM D, YYYY")}
          </Typography>
        </Stack>
      </MenuItem>
    );
  }

  return (
    <>
      <IconButton
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
          setTimeout(() => {
            markAllAsRead();
          }, 2000);
        }}
      >
        <Badge badgeContent={unread} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {notifications.length === 0 && (
          <Typography padding={10} variant="body2">
            No notifications
          </Typography>
        )}
        {notifications.map((notif, index) => (
          <NotificationItem
            key={index}
            type={notif.type}
            message={notif.message}
            date={notif.date}
            read={unread > 0 ? notif.read : true}
          />
        ))}
      </Menu>
    </>
  );
}
