import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Panel from "../../../components/v2/panel";
import { useState } from "react";
import Table from "../../../components/v2/table";
import CBT from "../../../utils/mws_components/cbt.jpg";
import Stress from "../../../utils/mws_components/stress.jpg";
import Counselling from "../../../utils/mws_components/counselling.webp";
import Depression from "../../../utils/mws_components/depression_tracking.jpg";
import { Loader } from "../../../components/Loader";
import Meditation from "../../../utils/meditation.jpg";
import ClimbingMountain from "../../../utils/climbing_mountain.jpg";
import Progress from "../../../utils/progress.jpg";
import { useNavigate } from "react-router-dom";
import { BaseURL, shareSite } from "../../../utils/consts";
import StackedBarChart from "../../../components/stackedBarChart";
import RevenueLineChart from "../../../components/v2/admin/revenueLineChart";
import ConsultantPieChart from "../../../components/v2/admin/consultantsPie";
import HorizontalBarGraph from "../../../components/v2/horizontalBarGraph";
import PSSStressGraph from "../../../components/v2/PSSGraph";
import DepressionGraph from "../../../components/v2/depressionGraph";
import axios from "axios";
import { useEffect } from "react";

const mwsComponents = [
  {
    label: "Monthly Stress Assessments",
    content:
      "These assessments help you understand and manage your stress levels better. Take at least 1 stress tracker test a month to get a score. Your score is the average of all quizzes taken in a month.",
    score: "up to 30 points",
    image: Stress,
    link: "/v2/client/stressTracker",
  },
  {
    label: "Monthly Depression Assessments",
    content:
      "These assessments help you understand and manage your depression levels better. Take at least 1 depression tracker test a month to get a score. Your score is the average of all quizzes taken in a month.",
    score: "up to 50 points",
    image: Depression,
    link: "/v2/client/depressionTracker",
  },
  // {
  //   label: "Easy Buddy AI Chats",
  //   content:
  //     "Easy Buddy is your personal mental wellness chat companion. Our AI analyses your chats and gives a score from -2 to +2 based on your mood and emotions. Having positive and helpful chats with Easy Buddy earns you better scores.",
  //   score: "up to 10 points",
  //   image: Buddy,
  //   link: "/v2/client/easyBuddy",
  // },
  {
    label: "Cognitive Behavioural Therapy Quizzes",
    content:
      "These quizzes help you understand your thoughts and feelings better. Take at least 1 CBT test a month to get a score. Your score is the average of all quizzes taken in a month.",
    score: "up to 10 points",
    image: CBT,
    link: "/v2/client/cbtQuiz",
  },
  // {
  //   label: "Mood Journal Entries",
  //   content:
  //     "Try to consistently write a journal about your day or mood, try to express gratitude and everything that is positive in your life. +1 for every positive journal and bonus points for consistency.",
  //   score: "up to 20 points",
  //   image: Journals,
  //   link: "/v2/client/moodJournal",
  // },

  {
    label: "Monthly Consultation",
    content:
      "Book and attend a consultation to get professional support. Expert counselors provide a safe and non-judgmental space to explore your thoughts and feelings, helping you to better understand and manage your mental well-being. Attend at least 1 meeting a month to get full 10 marks.",
    score: "10 points",
    image: Counselling,
    link: "/v2/client/services",
  },
];

const accessData = [
  {
    Data: "Access to the monthly total MWS",
    You: "Yes",
    Consultants: "No",
    Corporate: "Yes",
  },
  {
    Data: "The 4 component wise scoring breakup of MWS",
    You: "Yes",
    Consultants: "No",
    Corporate: "No",
  },
  {
    Data: "Customized recommendations based on your state of wellbeing",
    You: "Yes",
    Consultants: "No",
    Corporate: "No",
  },
  {
    Data: "Monthly participation in key activities (at least 1 stress test, 1 depression test, 1 CBT quiz, 1 consultation)",
    You: "Yes",
    Consultants: "Yes",
    Corporate: "Yes",
  },
  {
    Data: "Stress, Depression,CBT test scores",
    You: "Yes",
    Consultants: "Yes",
    Corporate: "No",
  },
  {
    Data: "Account Username (set by you)",
    You: "Yes",
    Consultants: "Yes",
    Corporate: "Yes",
  },
  {
    Data: "Actual Contact Details",
    You: "Yes",
    Consultants: "No",
    Corporate: "No",
  },
];
export default function MWS() {
  const [value, setValue] = useState(0);
  return (
    <Stack direction="row">
      <Panel />
      <Box sx={{ width: "100%" }}>
          <Tabs
            value={value}
            onChange={(_, newValue) => setValue(newValue)}
            aria-label="MWS tabs"
            sx={{ mt: 12, p: 0 }}
          >
            <Tab label="My MWS Records" value={0} />
            <Tab label="What is MWS?" value={1} />
          </Tabs>
        {value === 0 && <Records />}
        {value === 1 && <Info />}
      </Box>
    </Stack>
  );
}

function Records() {
  const [records, setRecords] = useState({
    totalMeetings: 0,
    totalStressTests: 0,
    totalDepressionTests: 0,
    totalCbtQuizzes: 0,
    mwsRecords: [],
    stressRecords: [],
    depressionRecords: [],
    cbt: [],
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const fetchRecords = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${BaseURL}/api/client/dashboard/mwsRecords`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      setRecords(res.data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchRecords();
  }, []);
  if (loading) return <Loader />;

  return (
    <Stack
      padding={{ xs: 2, md: 4 }}
      spacing={2}
      backgroundColor={`rgba(0, 0, 0, 0.05)`}
    >
      <Typography variant="h4" gutterBottom>
        My MWS Records
      </Typography>
      <Stack
        direction={{ xs: "row" }}
        flexWrap={"wrap"}
        paddingY={2}
        alignItems={"center"}
        justifyContent={"space-evenly"}
        gap={2}
      >
        {[
          {
            label: "Total CBT Quizzes Taken",
            total: records.totalCbtQuizzes,
            color: "#CDDC39",
          },
          {
            label: "Total Stress Assessments Taken",
            total: records.totalStressTests,
            color: "#2196F3",
          },
          {
            label: "Total Depression Assessments Taken",
            total: records.totalDepressionTests,
            color: "#F44336",
          },
          {
            label: "Total Consultations Taken",
            total: records.totalMeetings,
            color: "#00BCD4",
          },
        ].map((item, index) => (
          <Card
            key={index}
            sx={{ minWidth: 120, width: "100%", maxWidth: 250 }}
          >
            <CardContent>
              {" "}
              <Typography
                sx={{
                  fontSize: 12,
                  wordWrap: "break-word",
                  textAlign: "center",
                  // width: 120,
                }}
                color="text.secondary"
                gutterBottom
              >
                {item.label}
              </Typography>
              <Typography textAlign={"center"} variant="h3" color={"black"}>
                {item.total}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Stack>
      <Stack direction={{ md: "row" }} gap={2} spacing={1}>
        <RevenueLineChart
          title="Monthly MWS"
          dataKey="MWS"
          XdataKey="date"
          legendLabel="MWS"
          domain={[0, 100]}
          stroke="#4CAF50"
          data={records.mwsRecords}
          description="Monthly Mental Wellness Score"
        />
        <ConsultantPieChart
          data={[
            { name: "Stress Tests", value: 30 },
            { name: "Depression Tests", value: 50 },
            { name: "CBT Quizzes", value: 10 },
            { name: "Consultations", value: 10 },
          ]}
          consultants={100}
          label="MWS"
          title="MWS Weighted Score Out of 100"
          description="Activity wise weightage in calculation of Total MWS Score Out of 100"
        />
      </Stack>
      <Stack direction={{ md: "row" }} gap={2} justifyContent={"space-evenly"}>
        <StackedBarChart data={records.mwsRecords} />
        <HorizontalBarGraph
          data={[
            { name: "Consultations", Count: records.totalMeetings },
            { name: "Stress Reports", Count: records.totalStressTests },
            {
              name: "Depression Reports",
              Count: records.totalDepressionTests,
            },
            { name: "CBT Quizzes", Count: records.totalCbtQuizzes },
          ]}
          title={"History of CBT Scores"}
        />
      </Stack>
      <Stack direction={{ md: "row" }} gap={2} justifyContent={"space-evenly"}>
        <PSSStressGraph
          data={records.stressRecords}
          ranges={[
            { range: "0-13", label: "Low Stress", color: "#82ca9d" },
            { range: "14-26", label: "Medium Stress", color: "#ffc658" },
            { range: "27-40", label: "High Stress", color: "#ff0000" },
          ]}
        />
        <DepressionGraph data={records.depressionRecords} />
      </Stack>
    </Stack>
  );
}

function Info() {
  return (
    <Stack sx={{ px: { md: 8, xs: 3 }, py: 5 }} spacing={5}>
      <Box>
        <Typography variant="h4" gutterBottom>
          What is MWS?
        </Typography>
        <Typography variant="h6">
          Your monthly Mental Wellness Score (MWS) is a way to measure and track
          your overall mental wellbeing
        </Typography>
        <Typography variant="h6">
          It considers your interactions with the platform's tools and services
        </Typography>
        <Typography variant="h6">
          The MWS ranges from 0 to 100 points per month, with higher scores
          indicating better mental wellness
        </Typography>
      </Box>
      <Grid container spacing={2} columnSpacing={1}>
        {[
          {
            image: Progress,
            label:
              "Gain a clear picture of your mental health journey, celebrating progress, identifying opportunities for growth",
          },
          {
            image: Meditation,
            label:
              "Unlock a deeper understanding of your emotional landscape and thought patterns through consistent platform engagement",
          },
          {
            image: ClimbingMountain,
            label:
              "Experience a support system that adapts to your unique needs, providing personalized guidance, recommendations",
          },
        ].map((ele, index) => (
          <Grid item xs={12} md={4} key={index} sx={{ p: 0 }}>
            <Card>
              <CardMedia
                sx={{ height: 220 }}
                image={ele.image}
                title={ele.label}
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  {ele.label}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Typography variant="h6" gutterBottom>
        Remember, your MWS is a starting point for understanding and nurturing
        your mental health. By regularly engaging with the platform, honestly
        reflecting on your experiences, and committing to personal growth, you
        can unlock the full potential of the MWS and our resources to support
        your mental wellness journey. Improving your mental wellbeing is an
        investment in yourself, leading to a more peaceful, fulfilling, and
        happier life. Embrace this opportunity to prioritize your mental health
        and take steps towards a better you.
      </Typography>
      <Measurement />

      <Typography variant="h6" fontWeight={600}>
        These tools are meant to make a holistic evaluation of your mental
        wellbeing to help you in your mental wellness journey. Take every
        activity honestly focusing on your wellbeing to ensure accurate
        evaluations and personalized recommendations.
      </Typography>
      <AccessPrivacy />
      <Typography
        variant="h6"
        fontWeight={200}
        color={"GrayText"}
        textAlign={"center"}
      >
        Disclaimer:Please note that the CBT, Stress and Depression tests are
        clinical screening tools and not a definitive diagnosis. It is always
        recommended to consult with a qualified mental health professional for a
        comprehensive assessment and personalized therapy or consulting. The
        Mental Wellness Score is meant for your information purposes only and
        should not be used as a substitute for professional medical advice.
      </Typography>
    </Stack>
  );
}

function AccessPrivacy() {
  return (
    <>
      <Typography variant="h4" gutterBottom>
        Access and Privacy
      </Typography>
      <Table
        initialState={{ pagination: { pageIndex: 0, pageSize: 10 } }}
        columns={[
          {
            header: "Access Data",
            accessorKey: "Data",
            content: "data that you have shared on the platform",
            enableResizing: true, // Enable column resizing
            size: 100, // Set an initial width
            maxSize: 1000, // Set a maximum width (optional)
            minSize: 50, // Set a minimum width (optional)
            flexGrow: 1, // Set a flexible width (optional)
          },
          {
            header: "You",
            accessorKey: "You",
            content: "information that you have provided for your account",
            size: 20,
          },
          {
            header: "Experts",
            accessorKey: "Consultants",
            content: "consultants that you have been assigned to",
            size: 20,
          },
          {
            header: "Corporate Admin (Applicable to Corporate)",
            accessorKey: "Corporate",
            content: "administrators of the corporate account",
            size: 35,
          },
        ]}
        enableTopToolbar={false}
        enableBottomToolbar={false}
        enableColumnActions={false}
        data={accessData}
      />
    </>
  );
}

function Measurement() {
  const navigate = useNavigate();
  return (
    <Box>
      <Typography variant="h4">How to measure your MWS?</Typography>
      <Typography variant="h6" gutterBottom>
        Your MWS is based on your monthly usage of 4 key components:
      </Typography>
      <Grid container rowSpacing={5}>
        {mwsComponents.map((e, i) => {
          return (
            <Grid item xs={12} md={4} key={i}>
              <Card
                variant="elevation"
                sx={{ minHeight: 350, m: { xs: 0, md: 2 } }}
              >
                <CardMedia
                  sx={{ height: 250 }}
                  image={e.image}
                  title={e.label}
                />
                <CardContent sx={{ minHeight: 200 }}>
                  <Typography gutterBottom variant="h6" component="div">
                    {e.label + " (" + e.score + ")"}
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    {e.content}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" onClick={shareSite}>
                    Share
                  </Button>
                  <Button size="small" onClick={() => navigate(e.link)}>
                    Learn More
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
