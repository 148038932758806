import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import { FormControl } from "@mui/material";

export default function PasswordTextfield({
  label,
  id,
  password,
  setPassword,
  showPassword,
  setShowPassword,
  login,
}) {
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <FormControl
      sx={{ mt: 1, mb: 1 }}
      variant="outlined"
      autoComplete="off"
      name="qwerty"
    >
      <InputLabel htmlFor="outlined-adornment-password">
        {label || "Enter password"}
      </InputLabel>
      <OutlinedInput
        id={id ?? "outlined-adornment-password"}
        autoComplete="off"
        name="qwerty"
        label={label || "Enter password"}
        value={password ?? ""}
        type={showPassword ? "text" : "password"}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        onChange={(e) => {
          setPassword(e.target.value.trim());
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter" && login) {
            login();
          }
        }}
        variant="outlined"
      />
    </FormControl>
  );
}
