import {
  Alert,
  Button,
  Checkbox,
  ListItemText,
  Stack,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { BaseURL, IndianLanguages } from "../../utils/consts";
import { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import { useRef } from "react";
import ConsultantPanel from "../../components/v2/consultantPanel";
import NewConsultantCard from "../../components/v2/consultantCard";
import EasyButton2 from "../../components/v2/easyButton2";
export default function Profile() {
  const [user, setUser] = useState({});
  const [todayMeetings, setTodayMeetings] = useState([]);
  const [futureMeetings, setFutureMeetings] = useState([]);
  const [history, setHistory] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState("");

  const fileInputRef = useRef(null);
  const fetchData = async () => {
    try {
      setError("");
      let res = await axios.get(`${BaseURL}/api/specialist/myProfile`, {
        headers: {
          "x-auth-token": localStorage.getItem("consultantToken"),
        },
      });
      setUser(res.data.consultant);
      setReviews(res.data.reviews);
      setHistory(res.data.history);
      setFutureMeetings(res.data.upcoming);
      setTodayMeetings(res.data.today);
    } catch (err) {
      console.log(err);
      setError(err.response?.data?.message ?? err.message);
    }
  };
  const save = async () => {
    try {
      setError("");
      let res = await axios.post(
        `${BaseURL}/api/specialist/editProfile`,
        {
          ...user,
        },
        {
          headers: {
            "x-auth-token": localStorage.getItem("consultantToken"),
          },
        }
      );
      setUser(res.data);
      localStorage.setItem("consultantName", res.data.name);
      setEdit(false);
    } catch (err) {
      console.log(err);
      setError(err.response?.data?.message ?? err.message);
    }
  };
  const uploadCV = async (file) => {
    try {
      setError("");
      let res = await axios.get(`${BaseURL}/api/specialist/cvUrl2`, {
        headers: {
          "x-auth-token": localStorage.getItem("consultantToken"),
        },
      });
      const buffer = await file.arrayBuffer();
      // each entry of array should contain 8 bits
      const bytes = new Uint8Array(buffer);
      let postCV = await fetch(res.data, {
        method: "PUT",
        headers: {
          "Content-Type": "application/pdf",
        },
        body: bytes,
      });
      if (postCV.status === 200) {
        await axios.post(
          `${BaseURL}/api/specialist/cvUploadSuccess`,
          {
            url: postCV.url.split("?")[0],
          },
          {
            headers: {
              "x-auth-token": localStorage.getItem("consultantToken"),
            },
          }
        );
        setTimeout(() => window.location.reload(), 100);
      }
    } catch (err) {
      console.log(err);
      setError(err.response?.data?.message ?? err.message);
    }
  };
  const changeProfilePic = async (file) => {
    try {
      setError("");
      let res = await axios.get(`${BaseURL}/api/specialist/profilePicUrl`, {
        headers: {
          "x-auth-token": localStorage.getItem("consultantToken"),
        },
      });
      const buffer = await file.arrayBuffer();
      // each entry of array should contain 8 bits
      const bytes = new Uint8Array(buffer);
      let postPic = await fetch(res.data, {
        method: "PUT",
        headers: {
          "Content-Type": "image/png",
        },
        body: bytes,
      });
      if (postPic.status === 200) {
        await axios.post(
          `${BaseURL}/api/specialist/imageUploadSuccess`,
          {
            url: postPic.url.split("?")[0],
          },
          {
            headers: {
              "x-auth-token": localStorage.getItem("consultantToken"),
            },
          }
        );
        localStorage.setItem(
          "consultantProfile",
          JSON.stringify({ profilePic: postPic.url.split("?")[0] })
        );
        setTimeout(() => window.location.reload(), 100);
      }
    } catch (err) {
      console.log(err);
      setError(err.response?.data?.message ?? err.message);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Stack
      //   spacing={2}
      pt={8}
      sx={{
        marginLeft: { md: `${240}px` },
        marginTop: 5,
        paddingX: { lg: 10 },
      }}
    >
      <ConsultantPanel />

      <Stack
        id="profile"
        // pl={1.5}
        // pr={1.5}
        px={{xs:2,lg:0}}
        paddingBottom={1.5}
        paddingTop={0}
        sx={{
          "& > :not(style) + :not(style)": {
            marginTop: "0px !important",
          },
        }}
      >
        <Stack
          direction={{ md: "row" }}
          spacing={2}
          gap={5}
          alignItems={{ xs: "center", md: "start" }}
          justifyContent={"space-around"}
        >
          <label>
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={(e) => {
                changeProfilePic(e.target.files[0]);
              }}
            />
            <Stack mt={1}>
              <NewConsultantCard consultant={user} onClick={() => {}} />
            </Stack>
          </label>
          <Stack
            direction={{ lg: "row", md: "row", sm: "column", xs: "column" }}
            spacing={2}
            flexWrap={"wrap"}
            marginLeft={0}
            gap={5}
            justifyContent={"space-between"}
            alignItems={"baseline"}
            sx={{
              "& > *:not(:first-of-type)": {
                marginLeft: "0", // Setting the top margin to 0
              },
            }}
          >
            <TextField
              label="Full name"
              value={user?.name || ""}
              style={{ width: 300 }}
              onChange={(e) =>
                edit && setUser({ ...user, name: e.target.value })
              }
              variant="outlined"
              margin="normal"
            />
            <TextField
              label="Email"
              value={user?.email || ""}
              style={{ width: 300 }}
              onChange={(e) =>
                edit && setUser({ ...user, email: e.target.value })
              }
              variant="outlined"
              margin="normal"
            />
            <TextField
              label="Phone no."
              value={user?.phoneNo || ""}
              style={{ width: 300 }}
              variant="outlined"
              margin="normal"
            />
            <TextField
              label="Location(within 35 characters)"
              value={user?.location || ""}
              inputProps={{ maxLength: 35 }}
              style={{ width: 300 }}
              onChange={(e) =>
                edit && setUser({ ...user, location: e.target.value })
              }
              variant="outlined"
              margin="normal"
            />
            <TextField
              label="Profession"
              select
              value={user?.profession || ""}
              style={{ width: 300 }}
              onChange={(e) =>
                edit && setUser({ ...user, profession: e.target.value })
              }
              variant="outlined"
              margin="normal"
            >
              <MenuItem value={"Legal Buddy"}>Legal Buddy</MenuItem>
              <MenuItem value={"Psychologist"}>Psychologist</MenuItem>
              <MenuItem value={"Psychiatrist"}>Psychiatrist</MenuItem>
              <MenuItem value={"Coach"}>Coach</MenuItem>
              <MenuItem value={"Peer Support"}>Peer Support</MenuItem>
            </TextField>
            <TextField
              label="Specialisation(within 29 characters)"
              value={user?.specialisation || ""}
              inputProps={{ maxLength: 29 }}
              style={{ width: 300 }}
              onChange={(e) =>
                edit && setUser({ ...user, specialisation: e.target.value })
              }
              variant="outlined"
              margin="normal"
            />
            <TextField
              label="Education(within 34 characters)"
              value={user?.education || ""}
              style={{ width: 300 }}
              inputProps={{ maxLength: 34 }}
              onChange={(e) =>
                edit && setUser({ ...user, education: e.target.value })
              }
              variant="outlined"
              margin="normal"
            />
            <TextField
              label="Average Rating"
              value={user?.averageRating || 0}
              style={{ width: 300 }}
              variant="outlined"
              margin="normal"
            />
            <TextField
              label="No of ratings"
              value={user?.noOfRatings || 0}
              style={{ width: 300 }}
              variant="outlined"
              margin="normal"
            />
            <TextField
              multiline
              label="Experience(within 33 characters)"
              value={user?.experience || ""}
              inputProps={{ maxLength: 33 }}
              style={{ width: 300 }}
              onChange={(e) =>
                edit && setUser({ ...user, experience: e.target.value })
              }
              variant="outlined"
              margin="normal"
            />
            <TextField
              label="Awards/recognitions(within 37 characters)"
              value={user?.awardsnRecognition || ""}
              style={{ width: 300 }}
              inputProps={{ maxLength: 37 }}
              onChange={(e) =>
                edit && setUser({ ...user, awardsnRecognition: e.target.value })
              }
              variant="outlined"
              margin="normal"
            />{" "}
            <TextField
              select
              style={{ width: 300 }}
              SelectProps={{
                multiple: true,
                value: user?.spokenLanguages ?? [],
                renderValue: (selected) => (
                  <div style={{ whiteSpace: "normal" }}>
                    {selected.join(", ")}
                  </div>
                ),
              }}
              label="Spoken languages"
              onChange={(e) => {
                if (!edit) return;
                const valueArray =
                  typeof e.target.value === "string"
                    ? e.target.value.split(",")
                    : e.target.value;
                setUser({
                  ...user,
                  spokenLanguages: valueArray,
                });
              }}
              variant="outlined"
              margin="normal"
            >
              {IndianLanguages.map((e) => (
                <MenuItem key={e} value={e}>
                  <Checkbox checked={user?.spokenLanguages?.indexOf(e) > -1} />
                  <ListItemText primary={e} />
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="About yourself"
              value={user?.about || ""}
              style={{ width: "100%" }}
              multiline
              minRows={4}
              onChange={(e) =>
                edit && setUser({ ...user, about: e.target.value })
              }
              variant="outlined"
              margin="normal"
            />
          </Stack>
        </Stack>{" "}
        {error && <Alert severity="error">{error}</Alert>}
        {!edit ? (
          <Stack
            alignSelf={{ xs: "stretch", md: "auto", lg: "end" }}
            paddingTop={{ xs: 5, lg: 5 }}
            alignItems={"center"}
            direction={{ md: "row" }}
            spacing={2}
            gap={2}
          >
            <Button
              variant="outlined"
              sx={{ width: "100%", minWidth: "150px" }}
              onClick={() => window.open(user?.cv)}
            >
              View CV
            </Button>
            <Button
              variant="outlined"
              sx={{ width: "100%", minWidth: "150px" }}
              onClick={() => fileInputRef.current.click()}
            >
              Change CV
            </Button>
            <input
              type="file"
              ref={fileInputRef}
              hidden
              accept=".pdf" // Only accept PDF files
              onChange={(e) => {
                uploadCV(e.target.files[0]);
              }}
            />
            <EasyButton2
              label="Edit Profile"
              width={"100%"}
              onClick={() => setEdit(true)}
            ></EasyButton2>
          </Stack>
        ) : (
          <Stack
            width={{ xs: "100%", md: "60%" }}
            alignSelf={{ md: "end" }}
            direction={"row"}
            spacing={5}
            p={10}
          >
            <EasyButton2
              label="Save"
              width={"100%"}
              onClick={save}
            ></EasyButton2>
            <EasyButton2
              label="Cancel"
              width={"100%"}
              onClick={() => {
                fetchData();
                setEdit(false);
              }}
            ></EasyButton2>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
