import Image from "../utils/profile.jpg";
import { Box, Rating, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import moment from "moment";

export default function ReviewCard(props) {
  if (props.review)
    return (
      <Stack direction={"row"} spacing={{md:5}} width={"100%"}>
        <Box
          component="img"
          width={150}
          height={150}
          sx={{ borderRadius: "50%", display: { xs: "none", md: "block" } }}
          src={props.clientPic ?? Image}
          alt="Profile"
        />
        <Stack spacing={3} width={"100%"} paddingX={{ xs: 2, md: 10 }}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography variant="h6" fontWeight={600}>
              {props.clientName ?? `Anonymous`}{" "}
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontWeight: 200, fontSize: 15, alignSelf: "end" }}
            >
              {moment(props.review.timeStamp).format("DD-MM-YYYY")}
            </Typography>
          </Stack>
          <p>{props.review.message}</p>
          <Rating
            value={parseFloat(props.review.rating)}
            precision={0.5}
            readOnly
          ></Rating>
        </Stack>
      </Stack>
    );
}
