import { Alert, Box, Stack, Typography } from "@mui/material";
import Panel from "../../../components/v2/panel";
import { useState } from "react";
import axios from "axios";
import { BaseURL } from "../../../utils/consts";
import EastIcon from "@mui/icons-material/East";
import CBT from "../../../utils/mws_components/cbt.jpg";
import EasyButton2 from "../../../components/v2/easyButton2";
import { Loader } from "../../../components/Loader";
import { useNavigate } from "react-router-dom";
import { AnswerOption, ProgressIndicator } from "./stressTracker";
import { cbtScoresDict } from "../../../utils/consts";
import NeedlePie from "../../../components/v2/needlePie";
import CBTGraph from "../../../components/v2/cbtGraph";
import { panelPageMargin } from "../../../utils/consts";
import useScrollToTop from "../../../utils/useScrollToTop";
function getPredictionMessage(score, setPrediction) {
  const predictionMessages = [
    {
      maxScore: 9,
      severity: "Minimal symptoms",
      message:
        "Your CBT quiz results indicate that you are currently experiencing minimal symptoms of anxiety and depression. While it's great that you're not facing significant challenges at the moment, our platform offers resources to help you maintain your mental well-being. Consider exploring our AI chat buddy for support or trying out our PSS (Perceived Stress Scale) and BDI (Beck Depression Inventory) assessments for further insights. Remember, prioritizing your mental health is always important, and our platform is here to support you every step of the way.",
    },
    {
      maxScore: 18,
      severity: "Mild symptoms",
      message:
        "Your CBT quiz results suggest that you are experiencing mild symptoms of anxiety and depression. While these symptoms may not be significantly interfering with your daily life, our platform offers various resources to help you manage them effectively. Our AI chat buddy is available 24/7 to provide support and guidance whenever you need it. Additionally, our platform offers consultations with trained professionals who can help you develop a personalized plan to address your specific needs.",
    },
    {
      maxScore: 27,
      severity: "Moderate symptoms",
      message:
        "Based on your CBT quiz results, it appears that you are experiencing moderate symptoms of anxiety and depression. These symptoms may be impacting various aspects of your life, and it's important to take action to manage them. Our platform offers a range of resources to support you, including consultations with trained professionals who can provide you with personalized guidance and help you develop effective coping strategies. Remember, you don't have to face these challenges alone – our AI chat buddy is available 24/7 to provide support and guidance whenever you need it.",
    },
    {
      maxScore: 40,
      severity: "Severe symptoms",
      message:
        "Your CBT quiz results indicate that you are currently experiencing severe symptoms of anxiety and depression. We understand that these symptoms can feel overwhelming, but our platform is here to support you every step of the way. We strongly recommend scheduling a consultation with one of our trained professionals who can provide you with personalized guidance and help you develop a comprehensive treatment plan. In addition to consultations, our AI chat buddy is available 24/7 to provide immediate support and guidance. Remember, seeking help is a sign of strength, and our platform is here to empower you with the tools and support you need to overcome these challenges.",
    },
  ];

  const predictionMessage = predictionMessages.find(
    (message) => score <= message.maxScore
  );
  setPrediction({
    severity: predictionMessage ? predictionMessage.severity : "No severity",
    message: predictionMessage
      ? predictionMessage.message
      : "No prediction message found for the given score.",
  });
}

export default function Quiz() {
  const [quiz, setQuiz] = useState({});
  const [loading, setLoading] = useState(false);
  const [questionNumber, setQuestionNumber] = useState(-1);
  const [answers, setAnswers] = useState([]);
  const [score, setScore] = useState(0);
  const [prediction, setPrediction] = useState({
    severity: "",
    message: "",
  });
  const [records, setRecords] = useState([]);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const submit = async (cbtResponses, totalScore) => {
    try {
      setLoading(true);
      const res = await axios.post(
        `${BaseURL}/api/client/quiz/submitQuiz`,
        {
          responses: cbtResponses,
          quizID: quiz._id,
          quizNumber: quiz.number,
          score: totalScore,
        },
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      setRecords(res.data.records);
      getPredictionMessage(totalScore, setPrediction);
      setQuestionNumber(-2);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message);
      setError(err.response?.data?.message ?? err.message);
    }
  };
  const fetchData = async () => {
    try {
      setLoading(true);
      let res = await axios.get(`${BaseURL}/api/client/quiz/quiz`, {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      });
      if (res.data.isTakenToday) {
        setQuestionNumber(-2);
        setRecords(res.data.records);
        getPredictionMessage(
          res.data.records[res.data.records.length - 1].score,
          setPrediction
        );
      } else {
        setQuiz(res.data.quiz);
        setQuestionNumber(0);
        setScore(0);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message);
      setError(err.response?.data?.message ?? err.message);
    }
  };
  useScrollToTop();
  return (
    <Stack sx={{ ...panelPageMargin }}>
      <Panel />
      {error && (
        <Alert sx={{ marginY: 2 }} severity="error">
          {error}
        </Alert>
      )}
      {loading ? (
        <Loader />
      ) : questionNumber === -1 ? (
        <Intro
          testButton={
            <EasyButton2
              label="Track My CBT Score"
              endIcon={<EastIcon />}
              width={300}
              onClick={() => {
                fetchData();
              }}
            />
          }
        />
      ) : questionNumber === -2 ? (
        <Stack sx={{ margin: 2, position: "relative" }} alignItems={"center"}>
          {/* {
              <iframe
                title="lottie"
                src="https://lottie.host/?file=c3a758f7-699f-4a56-a774-480594ff7fc0/63hkyqhwCs.json"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  margin: 0,
                  zIndex: 1,
                  border: "none",
                }}
              ></iframe>
            } */}
          <Stack
            textAlign={"center"}
            alignItems={"center"}
            justifyContent={"center"}
            pt={2}
            px={{ xs: 0, md: 15 }}
          >
            <Typography variant="h5" color={"#143F6B"} gutterBottom pb={2}>
              Hi {localStorage.getItem("username")},<br /> Well Done. Your CBT
              Quiz Completed!
            </Typography>
            <NeedlePie
              score={records[records.length - 1].score}
              data={[
                {
                  name: "Minimal Symptoms (0-9)",
                  value: 9,
                  color: "#A8D5E2",
                },
                {
                  name: "Mild Symptoms (10-18)",
                  value: 9,
                  color: "#F9D371",
                },
                {
                  name: "Moderate Symptoms (19-27)",
                  value: 9,
                  color: "#F28705 ",
                },
                {
                  name: "Severe Symptoms (28-40)",
                  value: 13,
                  color: "#D35D6E",
                },
              ]}
            />
            <Typography pt={2} variant="h6">
              Today's score is {records[records.length - 1].score}
            </Typography>
            <Typography
              variant="h5"
              textAlign={"center"}
              fontWeight={"bold"}
              color={
                records[records.length - 1].score >= 0 &&
                records[records.length - 1].score <= 9
                  ? "green"
                  : records[records.length - 1].score <= 18
                  ? "yellowgreen"
                  : records[records.length - 1].score <= 27
                  ? "orange"
                  : "red"
              }
            >
              {records[records.length - 1].score <= 9
                ? "Minimal Symptoms"
                : records[records.length - 1].score <= 18
                ? "Mild Symptoms"
                : records[records.length - 1].score <= 27
                ? "Moderate Symptoms"
                : "Severe Symptoms"}{" "}
              Detected
            </Typography>
            <Stack
              sx={{
                alignItems: "center",
                border: "1px solid #E0E0E0",
                borderRadius: "20px",
                padding: "20px 20px 0 20px",
                color: "#143F6B",
                mb: 5,
                mt: 2,
              }}
            >
              <Typography
                fontWeight={"bold"}
                fontSize={{ md: 20, xs: 19 }}
                sx={{ color: "#662d91", mt: 0, p: 0 }}
                gutterBottom
              >
                Your Today's Wellness Analysis
              </Typography>
              <Typography
                fontSize={{ md: 18, xs: 15 }}
                sx={{
                  textAlign: "start",
                  pb: 2,
                }}
              >
                {prediction.message === "" ? (
                  <iframe
                    title="lottie"
                    style={{
                      margin: 0,
                      zIndex: 1,
                      border: "none",
                    }}
                    src="https://lottie.host/embed/c9642181-6e89-4b21-8887-93b0131a7cde/bSC6UxRV2t.json"
                  ></iframe>
                ) : (
                  '"' + prediction.message + '"'
                )}
              </Typography>
            </Stack>
            <CBTGraph data={records} />
            <Typography
              fontSize={{ md: 10, xs: 10 }}
              color={"GrayText"}
              textAlign={"start"}
              width={"100%"}
              ml={2}
              mt={0.5}
            >
              Note: Please note that this questionnaire is a screening tool and
              not a definitive diagnosis. It is always recommended to consult
              with a qualified mental health professional for a comprehensive
              assessment and personalized therapy or consulting.
            </Typography>{" "}
            <Stack
              direction={{ md: "row" }}
              width={"100%"}
              justifyContent={"end"}
              pt={4}
              gap={2}
              spacing={2}
            >
              <EasyButton2
                label="Talk to Easy Buddy"
                width={250}
                onClick={() => navigate("/v2/client/easyBuddy")}
              />
              <EasyButton2
                label="Consult An Expert"
                width={250}
                onClick={() => navigate("/v2/client/services")}
              />
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <Stack
          padding={{ md: 5, xs: 2 }}
          spacing={0}
          border={{ md: 1 }}
          margin={{ md: 5, xs: 1 }}
          borderRadius={5}
          borderColor={{ md: "lightgray" }}
          alignItems={"start"}
        >
          <Typography variant="h5">
            {questionNumber + 1}. {quiz.questions[questionNumber].question}
          </Typography>
          <Stack minHeight={300} paddingY={5} spacing={2} alignItems={"center"}>
            {quiz.questions[questionNumber].options.map((option, index) => (
              <AnswerOption
                key={option}
                option={option}
                level={index}
                onClick={() => {
                  if (quiz.questions.length === questionNumber + 1) {
                    submit(
                      [
                        ...answers,
                        {
                          question: quiz.questions[questionNumber].question,
                          answer: option,
                        },
                      ],
                      score + cbtScoresDict[option]
                    );
                  } else {
                    setAnswers([
                      ...answers,
                      {
                        question: quiz.questions[questionNumber].question,
                        answer: option,
                      },
                    ]);
                    setScore(score + cbtScoresDict[option]);
                    setQuestionNumber(questionNumber + 1);
                  }
                }}
              ></AnswerOption>
            ))}
          </Stack>
          <ProgressIndicator
            questionNo={questionNumber + 1}
            totalQuestions={quiz.questions.length}
          />
        </Stack>
      )}
    </Stack>
  );
}
export function Intro({ testButton }) {
  return (
    <Stack
      padding={{ xs: 2, md: 5 }}
      spacing={4}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Typography
        fontSize={{ xs: 25, md: 35 }}
        color={"#143F6B"}
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          paddingBottom: { md: 5 },
        }}
      >
        Cognitive Behavioral Therapy (CBT) Quiz
      </Typography>
      <Stack
        spacing={2}
        gap={2}
        alignItems={{ xs: "center", md: "start" }}
        direction={{ md: "row" }}
      >
        <Box
          component="img"
          src={CBT}
          sx={{ width: { md: "30%", xs: "100%" } }}
        />
        <Stack
          spacing={5}
          paddingX={{ md: 5 }}
          width={{ md: "100%" }}
          alignItems={"end"}
        >
          <Typography variant="h6">
            Cognitive Behavioural Therapy (CBT) is a widely used and
            evidence-based form of psychotherapy that focuses on the
            relationship between thoughts, emotions, and behaviours. The core
            principle of CBT is that our thoughts and beliefs influence our
            emotions and behaviours, and by identifying and modifying
            maladaptive or distorted thoughts, we can improve our emotional
            well-being and develop more adaptive coping strategies.
          </Typography>
          <Typography variant="h6">
            {" "}
            CBT is a structured and goal-oriented therapy that emphasizes the
            importance of the collaborative relationship between the therapist
            and the client. Through various techniques such as cognitive
            restructuring, behavioural experiments, and exposure therapy, CBT
            helps individuals challenge and change negative thought patterns,
            develop coping skills, and engage in more positive and productive
            behaviours. This CBT-based Online Mental Health Assessment MCQ
            questionnaire is designed to provide a preliminary screening of an
            individual's symptoms of anxiety and depression, two of the most
            common mental health concerns that CBT effectively addresses. By
            assessing the frequency and intensity of various cognitive,
            emotional, and behavioural symptoms, this questionnaire can help
            identify individuals who may benefit from CBT intervention.
          </Typography>
          <Typography variant="h6">
            The questionnaire covers a range of symptoms including worry, fear,
            physical symptoms, avoidance behaviours, mood, self-esteem, and
            daily functioning. By understanding the extent to which these
            symptoms impact an individual's life, mental health professionals
            can make informed decisions about the appropriate level of care and
            treatment options.
          </Typography>
          <Typography variant="h6">
            Furthermore, the questionnaire includes items that assess an
            individual's willingness and motivation to engage in therapy and
            make positive changes. This is crucial, as CBT requires active
            participation and commitment from the client to be most effective.
          </Typography>
          <Typography variant="h6">
            It is important to note that while this questionnaire can provide
            valuable insights, it is not a substitute for a comprehensive
            clinical assessment by a qualified mental health professional. The
            results of this questionnaire should be used as a starting point for
            further evaluation and to guide the development of a personalized
            treatment plan and consulting.
          </Typography>
          <Typography variant="h6">
            <b>Instructions: </b> This brief 10-question assessment helps access
            the frequency and intensity of symptoms related to anxiety and
            depression. Please answer each question honestly, selecting the
            option that best matches your experiences over the past two weeks.
            Once completed, your score will be calculated and interpreted, with
            personalized recommendations provided. Taking this step can empower
            you to improve your emotional wellbeing through proven therapeutic
            methods.
          </Typography>
          {testButton}
        </Stack>
      </Stack>
    </Stack>
  );
}
