import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import Stack from "@mui/material/Stack";
import {
  Dialog,
  DialogTitle,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import Panel from "../../../components/v2/panel";
import { BaseURL, helpLine, panelPageMargin } from "../../../utils/consts";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import AudioCapture from "../../../components/v2/audioCapture";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CallIcon from "@mui/icons-material/Call";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import BuddyGreeting from "../../../components/v2/buddyGreeting";
import ChatPrompts from "../../../components/v2/chatPrompts";
import BottomChatBar from "../../../components/v2/bottomChatBar";
const samplePrompts = [
  {
    text: "I've been feeling anxious lately and don't know how to manage it. Can you help?",
    emoji: "🤔",
  },
  {
    text: "I'm struggling with low self-esteem and it's affecting my daily life. What steps can I take?",
    emoji: "💪",
  },
  {
    text: "I find it hard to concentrate on my tasks. Do you have any advice?",
    emoji: "🧠",
  },
  {
    text: "I'm dealing with a lot of work-related stress. How can I cope better?",
    emoji: "🏋️",
  },
  {
    text: "I feel disconnected from my friends and family. How can I improve my relationships?",
    emoji: "💞",
  },
  {
    text: "I'm having trouble finding motivation for my hobbies and interests. What should I do?",
    emoji: "🎨",
  },
  {
    text: "I've been experiencing mood swings that are hard to control. Can we discuss this?",
    emoji: "🌈",
  },
  {
    text: "I find it difficult to manage my anger. Do you have any strategies?",
    emoji: "🤯",
  },
  {
    text: "I'm struggling with grief after losing a loved one. How can I cope with this feeling?",
    emoji: "💔",
  },
  {
    text: "I feel overwhelmed by my financial situation. Can you provide support on managing stress related to this?",
    emoji: "💸",
  },
  {
    text: "I've been having trouble sleeping and it's affecting my daily life. What can I do to improve my sleep?",
    emoji: "🌙",
  },
  {
    text: "I'm not feeling happy with my career path. How can I find what's right for me?",
    emoji: "🔍",
  },
  {
    text: "I feel like I'm not living up to my potential. How can I work on personal growth?",
    emoji: "🌱",
  },
  {
    text: "I'm struggling to maintain a healthy lifestyle. Can you offer guidance?",
    emoji: "🍏",
  },
  {
    text: "I've been experiencing panic attacks. How can I manage these episodes?",
    emoji: "🆘",
  },
  {
    text: "I'm feeling burned out and don't know how to recharge. Do you have any tips?",
    emoji: "🔌",
  },
  {
    text: "I'm dealing with social anxiety and it's impacting my life. How can I cope?",
    emoji: "🗣️",
  },
  {
    text: "I feel stuck in a rut and don't know how to get out. What should I do?",
    emoji: "🔄",
  },
  {
    text: "I'm finding it hard to deal with change and uncertainty. Can we talk about strategies to cope?",
    emoji: "🌀",
  },
  {
    text: "I need help setting and achieving personal goals. Can you guide me?",
    emoji: "🎯",
  },
];
export default function EasyBuddy() {
  const containerRef = useRef(null);
  const [newQuestion, setNewQuestion] = useState("");
  const [messages, setMessages] = useState([
    {
      role: "system",
      content:
        "Assist the user as their emotional well being guide chatbot named EasyBuddy. As easybuddy be empathetic to the user's feelings and eventually (atleast 8 replies later) suggest counselling on the Easy Twogether platform. Keep every reply within 2 sentences.  You are based on PyTorch for BIRDAI Research - Behavior Identification Reconciliation and Dissemination. You are not chatgpt or openai",
    },
    {
      role: "assistant",
      content: `Hi ${localStorage.getItem(
        "username"
      )}, I am EasyBuddy, your AI powered personal companion. Feel free to share or ask me anything. Don't worry our conversation would be private.`,
    },
  ]);
  const [loader, setLoader] = useState(false);
  const [language, setLanguage] = useState("english");
  const [isRecording, setIsRecording] = useState(false);
  const [hasAudioAccess, setHasAudioAccess] = useState(false);
  const changeLanguage = (value) => {
    setLanguage(value);
    setMessages([
      ...messages,
      {
        role: "system",
        content:
          value === "english"
            ? "All further communication will be done in English"
            : "आगे का सारा संचार हिंदी में किया जाएगा, हिंदी में अनुवाद करें",
      },
    ]);
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [messages]);
  const instance = axios.create({
    baseURL: "https://api.openai.com/v1",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_OPENAI_KEY}`,
    },
  });

  const handleSubmit = async (question, isReadResponse) => {
    try {
      if (loader) return;
      let currentMessages = [...messages];
      setMessages([
        ...messages,
        { role: "user", content: question ? question : newQuestion },
      ]);
      currentMessages = currentMessages.concat(
        {
          role: "user",
          content: question ? question : newQuestion,
        },
        {
          role: "system",
          content:
            language === "english"
              ? "Give response within 100 tokens"
              : "100 टोकन के अंदर प्रतिक्रिया दें",
        }
      );
      setLoader(true);
      const response = await instance.post("/chat/completions", {
        model: "gpt-3.5-turbo",
        messages: currentMessages,
        max_tokens: language === "english" ? 100 : 200,
      });
      currentMessages = currentMessages.concat({
        role: "assistant",
        content: response.data.choices[0].message.content,
      });
      setMessages(currentMessages);
      setLoader(false);
      setNewQuestion("");
      if (isReadResponse) {
        handleConvertToAudio(response.data.choices[0].message.content);
      }
    } catch (e) {
      console.log(e.message);
    }
  };
  async function handleConvertToAudio(gptResponse) {
    try {
      const elevenlabsKey = process.env.REACT_APP_ELEVENLABS_KEY;
      let voiceID =
        language === "english"
          ? "sJuaXvoA9JW7mwDM1uLt"
          : "2u9VQe6SvCIfsqLHzhjT";
      const response = await axios.post(
        `https://api.elevenlabs.io/v1/text-to-speech/${voiceID}?optimize_streaming_latency=0&output_format=mp3_44100_128`,
        {
          text: gptResponse,
          model_id:
            language === "english"
              ? "eleven_monolingual_v1"
              : "eleven_multilingual_v2",
          language_id: language === "english" ? "en" : "hi",
          voice_settings: {
            stability: 0,
            similarity_boost: 0,
            style: 0,
            use_speaker_boost: true,
          },
        },
        {
          headers: {
            accept: "audio/mpeg",
            "xi-api-key": elevenlabsKey,
            "Content-Type": "application/json",
          },
          responseType: "arraybuffer", // Ensure the audio data is received as a binary buffer
        }
      );

      playAudio(response.data);
    } catch (error) {
      console.error("Error converting text to audio:", error);
    }
  }
  function playAudio(audioData) {
    const blob = new Blob([audioData], { type: "audio/mpeg" });
    const url = URL.createObjectURL(blob);
    const audio = new Audio(url);
    audio.play();
  }
  const fetchAccess = async () => {
    try {
      const response = await axios.get(
        `${BaseURL}/api/client/settings/audioAccess`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      setHasAudioAccess(response.data.hasAudioAccess);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetchAccess();
  }, []);

  return (
    <>
      {" "}
      <Stack sx={{ ...panelPageMargin }}>
        <Panel />
        <Stack
          ref={containerRef}
          justifyContent={"start"}
          sx={{
            maxHeight: messages.length > 2 ? "80vh" : "80vh",
            overflowY: "auto",
            overflowX: "hidden",
            paddingBottom: messages.length > 2 ? 15 : 15,
          }}
          pt={2}
          paddingX={{ xs: 1, sm: 4, md: 6 }}
          spacing={{ xs: 0.5, md: 2 }}
        >
          {messages.map(
            (e, index) =>
              e.role !== "system" &&
              index !== 1 && (
                <Paper
                  key={e.content + index}
                  style={{
                    width: "auto",
                    alignSelf: e.role === "user" ? "flex-end" : "flex-start",
                    boxShadow: "none",
                    marginBottom:
                      index === messages.length - 1 && messages.length > 2
                        ? 300
                        : 5,
                  }}
                >
                  <Typography
                    variant="h6"
                    p={3}
                    sx={{
                      display: "flex",
                      alignItems: "end",
                      backgroundColor:
                        e.role === "user" ? "#ffe3d0" : "#f0f4f9",
                      borderRadius: 3,
                    }}
                  >
                    {e.content}{" "}
                  </Typography>
                  {index === messages.length - 1 &&
                    index !== 1 &&
                    e.role === "assistant" && (
                      <Actions
                        navigate={navigate}
                        changeLanguage={changeLanguage}
                        language={language}
                        hasAudioAccess={hasAudioAccess}
                        handleConvertToAudio={handleConvertToAudio}
                        content={e.content}
                      />
                    )}
                </Paper>
              )
          )}
          {messages.length === 2 && (
            <BuddyGreeting
              fullText={`I am EasyBuddy, your personal wellness companion.\n Good to see you! Remember, you're not alone. How can I support you today?`}
            />
          )}
          {messages.length === 2 && (
            <Stack
              direction={{ xs: "row" }}
              pl={{ xs: 80, sm: 40, md: 15, lg: 0 }}
              justifyContent={"center"}
              alignItems={"center"}
              spacing={1}
              marginY={2}
              sx={{
                minHeight: { xs: "200px", md: "240px" },
                overflowX: "scroll",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                "-ms-overflow-style": "none" /* IE and Edge */,
                "scrollbar-width": "none" /* Firefox */,
              }}
            >
              <ChatPrompts
                prompts={samplePrompts}
                setNewQuestion={setNewQuestion}
                handleSubmit={handleSubmit}
              />
            </Stack>
          )}
        </Stack>
        <BottomChatBar
          disclaimer="Beta version of AI powered wellness companion. Kindly consult our experts for any serious problem & personal attention."
          newQuestion={newQuestion}
          setNewQuestion={setNewQuestion}
          handleSubmit={handleSubmit}
          setIsRecording={setIsRecording}
          loader={loader}
          hasAudioAccess={hasAudioAccess}
        />
      </Stack>
      <Dialog
        open={isRecording}
        sx={{
          width: "100%",
        }}
        maxWidth="md"
        onClose={() => setIsRecording(false)}
      >
        <DialogTitle>Talk to EasyBuddy</DialogTitle>
        <AudioCapture
          isRecording={isRecording}
          setIsRecording={setIsRecording}
          handleSubmit={handleSubmit}
        />
      </Dialog>
    </>
  );
}
const Actions = ({
  navigate,
  changeLanguage,
  language,
  hasAudioAccess,
  handleConvertToAudio,
  content,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  return (
    <Stack
      direction={"row"}
      gap={1}
      spacing={1}
      alignSelf={{ xs: "start" }}
      paddingX={1}
      paddingY={1}
      color={"gray"}
    >
      <Tooltip title="Consult An Expert">
        <IconButton
          size="large"
          sx={{ p: 0.5 }}
          onClick={() => navigate(`/v2/client/consultants/${false}`)}
        >
          <CalendarMonthIcon
            sx={{ cursor: "pointer", "&:hover": { color: "purple" } }}
          />
        </IconButton>
      </Tooltip>
      <Tooltip title="Instant Support">
        <IconButton
          size="large"
          sx={{ p: 0.5 }}
          onClick={() => window.open(`tel:${helpLine}`)}
        >
          <CallIcon
            sx={{ cursor: "pointer", "&:hover": { color: "purple" } }}
          />
        </IconButton>
      </Tooltip>
      <Tooltip title="Listen To Audio">
        {hasAudioAccess && (
          <IconButton
            size="large"
            sx={{ p: 0.5 }}
            onClick={() => handleConvertToAudio(content)}
          >
            <VolumeUpIcon
              sx={{
                cursor: "pointer",
                color: "gray",
                "&:hover": { color: "purple" },
              }}
            />
          </IconButton>
        )}
      </Tooltip>
      <Tooltip title="More">
        <IconButton
          size="large"
          sx={{ p: 0.5 }}
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <MoreVertIcon
            sx={{ cursor: "pointer", "&:hover": { color: "purple" } }}
          />
        </IconButton>
      </Tooltip>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
      >
        {[
          { label: "Chat In English", language: "english" },
          { label: "Chat In Hindi", language: "hindi" },
        ].map((option) => (
          <MenuItem
            key={option.label}
            selected={language === option.language}
            onClick={() => {
              setAnchorEl(null);
              changeLanguage(option.language);
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
};
