import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import CustomDatePicker from "../datePicker";
import axios from "axios";
import { useEffect, useState } from "react";
import { BaseURL } from "../../../utils/consts";
import EasyButton2 from "../easyButton2";

export default function RescheduleMeeting({
  rescheduleMeeting,
  setRescheduleMeeting,
}) {
  const [consultants, setConsultants] = useState([]);
  const [slots, setSlots] = useState([]);
  const reshedule = async () => {
    try {
      const response = await axios.put(
        `${BaseURL}/api/viewPoint/adminDashboard/reschedule/rescheduleMeeting`,
        {
          meetingId: rescheduleMeeting.id,
          specialistId: rescheduleMeeting.consultant._id,
          date: rescheduleMeeting.date,
          slot: rescheduleMeeting.slot,
        },
        {
          headers: {
            "x-auth-token":
              localStorage.getItem("adminToken") ||
              localStorage.getItem("operatorToken"),
          },
        }
      );
      alert(response.data.message);
      window.location.reload();
    } catch (err) {
      console.log(err);
      alert(err.response?.data?.message ?? err.message);
    }
  };

  useEffect(() => {
    const fetchConsultants = async () => {
      try {
        if (!rescheduleMeeting.id) return;
        const response = await axios.get(
          `${BaseURL}/api/viewPoint/adminDashboard/reschedule/consultants/${rescheduleMeeting.id}`,
          {
            headers: {
              "x-auth-token":
                localStorage.getItem("adminToken") ||
                localStorage.getItem("operatorToken"),
            },
          }
        );
        setConsultants(response.data.consultants);
        setRescheduleMeeting({
          ...rescheduleMeeting,
          consultant: response.data.consultants[0],
        });
        fetchSlots({
          consultant: response.data.consultants[0],
        });
      } catch (err) {
        console.log(err);
        alert(err.response?.data?.message ?? err.message);
      }
    };
    if (rescheduleMeeting.open === true) fetchConsultants();
  }, [rescheduleMeeting.open]);
  const fetchSlots = async ({
    consultant = rescheduleMeeting.consultant,
    newDate = rescheduleMeeting.date,
  }) => {
    try {
      let res = await axios.post(
        `${BaseURL}/api/viewPoint/adminDashboard/reschedule/getSlots`,
        {
          specialistId: consultant._id,
          date: newDate,
        },
        {
          headers: {
            "x-auth-token":
              localStorage.getItem("adminToken") ||
              localStorage.getItem("operatorToken"),
          },
        }
      );
      setSlots(res.data.map((item) => item.slot));
      setRescheduleMeeting({
        ...rescheduleMeeting,
        slot: res.data[0]?.slot,
        consultant: consultant,
        date: newDate,
      });
    } catch (err) {
      console.log(err.message);
      alert(err.response?.data?.message ?? err.message);
    }
  };
  return (
    <Dialog
      open={rescheduleMeeting.open}
      fullWidth
      onClose={() => {
        setRescheduleMeeting({ open: false, id: "", date: "", consultant: "" });
      }}
    >
      <DialogTitle>Reschedule Meeting</DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          gap: 2,
        }}
      >
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={rescheduleMeeting.consultant}
          onChange={(e) => {
            setRescheduleMeeting({
              ...rescheduleMeeting,
              consultant: e.target.value,
            });
            fetchSlots({
              consultant: e.target.value,
            });
          }}
        >
          {consultants.map((item) => (
            <MenuItem key={item._id} value={item}>
              {item.name} - {item.phoneNo}
            </MenuItem>
          ))}
        </Select>
        <CustomDatePicker
          date={rescheduleMeeting.date}
          minDate={new Date()}
          maxDate={new Date(new Date().setDate(new Date().getDate() + 30))}
          onChange={(date) => {
            setRescheduleMeeting({ ...rescheduleMeeting, date: date });
            fetchSlots({ newDate: date });
          }}
        />
        {slots.length > 0 ? (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={rescheduleMeeting.slot}
            onChange={(e) =>
              setRescheduleMeeting({
                ...rescheduleMeeting,
                slot: e.target.value,
              })
            }
          >
            {slots.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <Typography color={"red"}>No slots available</Typography>
        )}
        <DialogContentText>
          Note: This action cannot be undone and it will be notified to the
          client, new consultant and existing consultant.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <EasyButton2
          label="Cancel"
          onClick={() =>
            setRescheduleMeeting({
              open: false,
              id: "",
              date: "",
              consultant: "",
            })
          }
        />
        <EasyButton2 label="Reschedule Meeting" onClick={() => reshedule()} />
      </DialogActions>
    </Dialog>
  );
}
