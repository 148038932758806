export const BaseURL =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? "http://localhost:5002"
    : window.location.origin;

export const helpLine = "+91 6291075366";
export const whatsappNumber = "916291075366";
export const drawerWidth = window.innerWidth > 1000 ? 200 : window.innerWidth;
export const drawerWidth2 = { xs: "100%", md: "240px" };
export const panelPageMargin = {
  marginLeft: { md: `${240}px` },
  marginTop: 10,
};
export const headingFontSize = {
  lg: "1.5rem",
  md: "1.5rem",
  sm: "1.5rem",
  xs: "1.5rem",
};
export const emptyString =
  "................................................................................................................................................................................................................................................................................................................................................................";

export function validateEmail(email) {
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return emailRegex.test(email);
}

export const validatePhoneNo = (phoneNo) => {
  const phoneNoRegex = /^(?!(\d)\1{9})\d{10}$/;
  return phoneNoRegex.test(String(phoneNo));
};

export const validateString = (value, label) => {
  if (typeof value !== "string") {
    return `${label} must be a string`;
  }
  if (value.length < 1) {
    return `${label} must not be empty`;
  }
  if (value.length > 500) {
    return `${label} must not exceed 500 characters`;
  }
  if (!/^[A-Za-z0-9\s.]*$/.test(value)) {
    return `${label} must only contain alphabets, numbers, dots and spaces`;
  }
  return "";
};

export const validateName = (value) => {
  if (typeof value !== "string") {
    return "Name must be a string";
  }
  if (value.length < 3 || value.length > 20) {
    return "Name must be between 3 and 20 characters long";
  }
  if (!/^[A-Za-z. ]+$/.test(value)) {
    return "Name must only contain alphabets, dots and spaces";
  }
  return "";
};

export const validateUsername = (value) => {
  if (typeof value !== "string") {
    return "Username (display name) must be a string";
  }
  if (value.length < 3 || value.length > 20) {
    return "Username (display name) must be between 3 and 20 characters long";
  }
  if (!/^[A-Za-z\s]*$/.test(value)) {
    return "Username (display name) must only contain alphabets and spaces";
  }
  return "";
};

export const safeJSONParse = (jsonString, defaultValue = null) => {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    return defaultValue;
  }
};

export const IndianLanguages = [
  "English",
  "Hindi",
  "Bengali",
  "Marathi",
  "Assamese",
  "Bodo",
  "Dogri",
  "Gujarati",
  "Kannada",
  "Kashmiri",
  "Konkani",
  "Maithili",
  "Malayalam",
  "Manipuri",
  "Nepali",
  "Odia",
  "Punjabi",
  "Sanskrit",
  "Santali",
  "Sindhi",
  "Tamil",
  "Telugu",
  "Urdu",
];

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const shareSite = async () => {
  if (navigator.share) {
    try {
      await navigator.share({
        title:
          "Online Mental Health Support | Counseling Services - PeaceTwogether",
        text: "Find professional online mental health support and counseling services in India. Our platform offers confidential sessions with licensed therapists for various mental health concerns.",
        url: "https://peacetwogether.com",
      });
    } catch (error) {
      console.error("Error sharing content:", error);
    }
  } else {
    // Fallback for browsers that do not support the Web Share API
    console.log("Web Share not supported. Implement fallback here.");
  }
};

export const cbtScoresDict = {
  Never: 0,
  Rarely: 1,
  Sometimes: 2,
  Often: 3,
  Always: 4,
  "Not at all": 0,
  Slightly: 1,
  Moderately: 2,
  Significantly: 3,
  Extremely: 4,
  "Not at all willing": 0,
  "Slightly willing": 1,
  "Moderately willing": 2,
  "Very willing": 3,
  "Extremely willing": 4,
  "Not at all motivated": 0,
  "Slightly motivated": 1,
  "Moderately motivated": 2,
  "Very motivated": 3,
  "Extremely motivated": 4,
  "Not at all open": 0,
  "Slightly open": 1,
  "Moderately open": 2,
  "Very open": 3,
  "Extremely open": 4,
  "Not at all committed": 0,
  "Slightly committed": 1,
  "Moderately committed": 2,
  "Very committed": 3,
  "Extremely committed": 4,
};
