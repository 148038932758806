import React, { useMemo } from "react";
import { MaterialReactTable } from "material-react-table";
import { ExportToCsv } from "export-to-csv"; //or use your library of choice here
import { Box, IconButton, Typography } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
const Table = (props) => {
  //should be memoized or stable
  const columns = useMemo(() => [...props.columns], [props.columns]);
  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: true,
    // headers: columns.map((c) => c.header),
  };

  const csvExporter = new ExportToCsv(csvOptions);
  const handleExportData = () => {
    const flatData = props.data.map((item) => flattenObject(item));
    console.log(flatData);
    csvExporter.generateCsv(flatData);
  };
  return (
    <MaterialReactTable
      columns={columns}
      data={props.data ?? []}
      initialState={
        props.initialState ?? {
          pagination: { pageIndex: 0, pageSize: 5 },
          columnOrder: [
            "_id",
            "name",
            "adminName",
            "email",
            "phoneNo",
            "username",
            "age",
            "gender",
            "isMarried",
            "signupTimestamp",
            "city",
            "country",
            "specialisation",
            "averageRating",
            "noOfRatings",
            "adminVerified",
            "gstNumber",
            "location",
            "count",
            "amount",
            "more",
          ],
        }
      }
      enableTopToolbar={props.enableTopToolbar ?? true}
      enableBottomToolbar={props.enableBottomToolbar ?? true}
      enableColumnActions={props.enableColumnActions ?? true}
      // enableColumnResizing={props.enableColumnResizing ?? true}
      defaultColumn={{ size: 150 }}
      renderTopToolbarCustomActions={() => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "start",
            padding: 0.5,
          }}
        >
          {props.title && (
            <Typography fontWeight={"bold"} variant="h6">
              {props.title}
            </Typography>
          )}
          <IconButton
            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
            onClick={handleExportData}
          >
            <FileDownloadIcon />
          </IconButton>
          {props.TopToolbarCustomActions || <></>}
        </Box>
      )}
    />
  );
};

export default Table;

function flattenObject(ob) {
  const toReturn = {};

  for (const i in ob) {
    // Check if the current property belongs to the object itself
    if (!ob.hasOwnProperty(i)) continue;

    // If the current property's value is an object and not an array
    if (typeof ob[i] === "object" && !Array.isArray(ob[i])) {
      // Flatten the nested object recursively
      const flatObject = flattenObject(ob[i]);

      for (const x in flatObject) {
        // Ensure we're processing properties of the flatObject itself
        if (!flatObject.hasOwnProperty(x)) continue;

        // Merge the flattened properties into the toReturn object
        toReturn[i + "." + x] = flatObject[x];
      }
    }
    // If the current property's value is not an object (or an array)
    else if (!Array.isArray(ob[i])) {
      toReturn[i] = ob[i];
    }
  }

  return toReturn;
}
