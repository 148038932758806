import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import EasyButton2 from "../components/v2/easyButton2";

export default function AccessDenied(props) {
  const navigate = useNavigate();
  return (
    <Stack
      justifyContent={"center"}
      alignItems={"center"}
      height={"100vh"}
      padding={10}
      spacing={{ lg: 5 }}
    >
      <Typography
        variant="h5"
        fontFamily={"Arial, sans-serif"}
        fontWeight={700}
        paddingBottom={5}
      >
        Access Denied
      </Typography>
      <iframe
        title="lottie"
        width="100%"
        height="100%"
        style={{ border: "none" }}
        src="https://lottie.host/?file=45e58e04-d2d4-4b8e-b11d-2cab9a7723ec/W3AWYzam7Y.json"
      ></iframe>
      <EasyButton2
        label="Login"
        width="200px"
        onClick={() => navigate(props.loginPath)}
      />
    </Stack>
  );
}
